@use '_color';
@use 'bootstrap';

.navbar-right {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  .icon {
    font-size: 2rem;
    line-height: bootstrap.$line-height-base;
  }

  svg,
  .icon,
  .user-name,
  .settings-link {
    color: color.$gray-200;
  }

  .settings-link:hover,
  .settings-link:active,
  .icon:hover {
    color: color.$primary;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown {
    align-items: center;
    display: flex;
  }

  .dropdown-item {
    cursor: pointer;

    &:hover {
      background-color: color.$gray-800;
      color: color.$gray-200;
    }
  }

  .header-item {
    align-items: center;
    display: flex;
    margin-right: 1rem;
  }
}

.no-chrome-page {
  .navbar-right {
    flex-grow: 1;
    height: 100%;

    .dropdown {
      height: 100%;
    }

    .header-item {
      height: 100%;
    }

    .icon {
      font-size: 3.125rem;
    }

    svg {
      height: 2.5rem;
      width: 2.5rem;
    }

    .user-name {
      font-size: 1.25rem;
      font-style: italic;
    }
  }
}
