@use '_color';

.component-jem-stv {
  .jem-stv {
    height: 100%;
  }

  .description .eflex-icon {
    height: 30px;
    margin-top: -6px;
    width: 30px;
  }

  .status {
    &.success {
      color: color.$success;
    }

    &.danger {
      color: color.$danger;
    }

    &.info {
      color: color.$non-photo-blue;
    }
  }

  .inline-error {
    color: color.$danger;
    font-size: 14px;
    margin-top: -18px;

    i {
      margin-right: 8px;
    }
  }

  .fault-message {
    background-color: color.$danger;
    color: white;
    font-size: 16px;
    margin: 0 -1rem;
    margin-bottom: 0.15rem !important;
    padding: 5px;
    text-align: center;

    i {
      margin-right: 8px;
    }
  }

  label {
    font-size: 12px;
  }

  button {
    font-size: 1.5rem;
    font-weight: bold;
    height: 100%;
    max-height: 5rem;
    min-height: 2rem;
    width: 100%;
  }

  .jem-process-data-inputs {
    .process-data-def-name {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      text-transform: uppercase;
    }

    .process-data-def-limits {
      color: #fff;
      font-size: 12px;
      text-align: left;
      text-transform: uppercase;

      .blank {
        display: none;
      }
    }

    .eflex-radio-button label {
      color: #8b8f94;
    }

    .eflex-radio-button :checked + label {
      color: #fff;
    }
  }

  .component-option,
  .fault-message {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .jem-task-table .form-row {
    padding-bottom: 5px;
    padding-top: 5px;

    &.header-row {
      flex-wrap: nowrap;
      font-size: 0.75rem;
    }

    .detail-text-value {
      flex-direction: column;
    }

    .detail-text-value div {
      font-size: 1.25rem;
      line-height: 1.1;
    }

    .detail-text-value .icon {
      align-self: start;
      font-size: 0.75rem;
      margin: auto;
      margin-bottom: 4px;

      &.icon-x,
      &.icon-checkmark {
        font-size: 1.25rem;
      }
    }

    .torque-col {
      min-width: 74px;
    }

    &:not(.header-row) .tightening-col {
      align-self: start;
    }
  }
}
