.component-bi-report-dropdown {
  .ember-power-select-trigger {
    background-color: transparent;
    border-color: #736e68;
    box-shadow: none;
    color: #fff;
    cursor: pointer;
  }

  .ember-power-select-placeholder {
    color: #fff;
    font-size: 12px;
    letter-spacing: 1px;
  }
}
