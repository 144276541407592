@use '../_color';

$inner-left-margin: 45px;

.plant-hardware {
  .no-hover-row:hover,
  input[readonly] {
    background-color: color.$gray-100 !important;
  }

  .hardware-list {
    .col-hardware-status {
      max-width: 150px;
    }
  }
}
