@use 'sass:color';
@use 'sass:map';
@use '_color' as styles-color;
@use 'bootstrap';

.component-nav-bar {
  height: 100%;
  overflow-y: auto;

  .scroll-nav {
    height: 100%;
    max-height: 98%;
    overflow-x: hidden;
  }

  .primary-nav-container {
    height: 100%;
    position: relative;
    width: 200px;

    // When left nav menu is collapsed make sure that nav links text is not overlapped with content
    &.is-closed {
      .nav-flyout {
        .sub-menu ul a {
          padding-left: 7px;
        }

        .nav-item-label {
          display: none;
        }

        .badge-wrapper {
          flex-direction: column;
          margin-left: -5px;
          margin-top: -3px;
          position: absolute;

          .badge {
            font-size: 60%;
            margin-bottom: 2px;
            margin-left: 0;
          }
        }
      }
    }

    .nav-flyout {
      a {
        background: styles-color.$gray-100;
        border-bottom: 1px solid color.adjust(bootstrap.$border-color, $lightness: -10%);
        color: styles-color.$default-text;
        display: block;
        font-size: 14px;
        font-weight: 600;
        padding: 5px 5px 3px 0;
        white-space: nowrap;
      }

      .active {
        background-color: rgba(styles-color.$primary, 0.5);
        border: 1px solid rgba(black, 0.07);
        pointer-events: none;
      }

      a:hover,
      a:active {
        background-color: white;
        outline: 0;
        text-decoration: none;
      }

      .badge-wrapper {
        display: inline-flex;
        flex-direction: row;

        .badge {
          color: white;
          margin-left: 5px;
        }

        .badge.warning {
          background-color: styles-color.$warning;
        }

        .badge.danger {
          background-color: #d9534f;
        }
      }

      .sub-menu {
        .section-hidden {
          display: none;
        }

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;

          .active {
            background-color: rgba(styles-color.$primary, 0.5);
          }

          a {
            background: color.adjust(styles-color.$gray-0, $lightness: 2%);
            font-weight: 500;
            padding-left: 10px;
          }

          a:hover {
            background-color: white;
            font-size: 14px;
          }
        }

        ul:first-child {
          border-top: none;
        }

        ul:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .tiny {
    .nav-flyout {
      height: 100%;
      position: relative;
      width: 380px;

      .sub-menu {
        position: relative;

        .section-hidden {
          display: inherit;
        }

        ul {
          left: -9999px;
          position: absolute;
          z-index: map.get(bootstrap.$zindex-levels, 'popover');
        }
      }

      .sub-menu:hover ul {
        border-right: 1px solid color.adjust(bootstrap.$border-color, $lightness: -10%);
        box-shadow: 0 3px 3px rgba(black, 0.9);
        cursor: pointer;
        left: 45px;
        margin: 0;
        padding: 0;
        top: 0;

        a {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }

  .nav-item-icon {
    display: inline-block;
    margin-left: 7px;
    max-width: 30px;
    text-align: center;
    width: 24px;

    i,
    img {
      margin: auto;
      padding: 0;
    }

    img {
      max-width: 16px;
      width: 100%;
    }
  }
}
