@use '_functions';

.component-split-view {
  .split-view-section {
    float: left;
    height: 100%;
    margin-bottom: -20px;
  }

  .gutter-vertical {
    background: functions.image-url('split-handle-h.svg') no-repeat center;
    background-size: contain;

    &:hover {
      cursor: ns-resize;
    }
  }

  .gutter-horizontal {
    background: functions.image-url('split-handle-v.svg') no-repeat center;
    background-size: contain;
    float: left;
    height: 100%;

    &:hover {
      cursor: ew-resize;
    }
  }
}
