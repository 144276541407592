// update style guide after changing colors

// Brand Colors
$eagle-green: #025064;
$persian-blue: #0d6b9e;
$non-photo-blue: #a9dcf8;
$pearl-aqua: #90d2b5;
$salmon: #ff8873;

// Brand Darken
$darken-persian-blue: #0a5a85;
$darken-non-photo-blue: #86aec4;
$darken-english-vermillion: #b23e3c;
$darken-ufo-green: #17b252;
$darken-maize: #d2a102;

// Grayscale
$gray-1000: #000;
$gray-900: #252727;
$gray-800: #3b3e3e;
$gray-700: #4c5252;
$gray-600: #616868;
$gray-500: #7e8686;
$gray-400: #98a0a2;
$gray-300: #b1baba;
$gray-200: #c8d0d1;
$gray-100: #eef2f5;
$gray-0: #fff;

// States
$english-vermillion: #d8524e;
$maize: #ffcb57;
$ufo-green: #1bcf5f;

$primary: $persian-blue;
$secondary: $gray-400;
$success: $ufo-green;
$danger: $english-vermillion;
$warning: $maize;
$info: $non-photo-blue;
$light: $gray-100;
$dark: $gray-900;

$default-text: $gray-700;
$light-text: $gray-400;
$default-hover: rgba(0, 0, 0, 3%);
