@use '_color' as color2;

.component-styleguide-color-collection-color-display {
  .color-circle {
    border-radius: 30px;
    height: 30px;
    min-width: 30px;
    width: 30px;
  }

  .alias {
    color: color2.$light-text;
    cursor: alias;
    font-size: 0.875rem;
  }

  .hex {
    font-family: monospace;
  }
}
