.component-jem-task-specific-display-decision-details {
  .radio-btn-group {
    .btn {
      border-radius: 3px !important;
      margin: 5px;
      max-width: 300px;
      white-space: normal;
    }
  }
}
