.component-jem-spindle {
  .icon {
    align-self: center;
    font-size: 1.5rem;
    max-width: 20px;
    padding-bottom: 0.375rem;
    width: 24px;
  }

  .icon-x {
    padding-left: 3px;
  }

  .status-display {
    max-width: 400px;
  }

  &.is-stale {
    .detail-text-value,
    .spindle-error {
      opacity: 0.6;
    }
  }
}
