@use '../_color';
@use 'bootstrap';
@use 'sass:math';
@use 'sass:map';

.bi-page {
  height: 100%;

  .toggle {
    background-color: color.$gray-0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: math.div(bootstrap.$grid-gutter-width, 2);
  }

  .criteria-selection {
    background-color: color.$gray-0;
    min-width: 700px;
    overflow: auto;

    .criteria-selection-content {
      padding-right: math.div(bootstrap.$grid-gutter-width, 2);
      padding-top: math.div(bootstrap.$grid-gutter-width, 2);
    }
  }

  .station-quality-p-chart {
    height: 90%;
  }

  .subgroup-size-wrapper {
    height: 10%;
  }

  .bi-no-data {
    color: color.$primary;
    font-size: 2rem;
  }

  .component-bi-dropdown {
    .ember-power-select-trigger {
      background-color: transparent;
      border-color: #fff;
      box-shadow: none;
      color: #fff;
      cursor: pointer;
      position: relative;
      width: 125px;
      z-index: map.get(bootstrap.$zindex-levels, 'select-options');
    }
  }

  .component-bi-chart-with-dropdown {
    .chart-row {
      position: relative;
      top: -30px;
    }
  }
}
