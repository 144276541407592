@use 'sass:color';
@use '_color' as color2;
@use 'bootstrap';
@use '_mixins';

.no-chrome-page,
.chrome-page {
  height: 100vh;
}

.chrome-page {
  background-color: color2.$gray-100;
  display: grid;
  grid-template-areas:
    'header header'
    'nav-bar workspace'
    'footer footer';
  grid-template-columns: auto 1fr;
  grid-template-rows: 90px 1fr auto;
}

.no-chrome-page {
  background: color2.$gray-800;
  display: grid;
  grid-template-areas:
    'no-chrome-workspace no-chrome-workspace'
    'footer footer';
  grid-template-rows: minmax(0, 1fr) auto;

  .work-instructions-page {
    grid-area: no-chrome-workspace;
  }

  .main-footer {
    flex-wrap: wrap;
    grid-area: footer;
    width: 100%;
    z-index: 999;

    span.active {
      color: color2.$non-photo-blue;
    }
  }
}

.main-header {
  grid-area: header;

  .header-menu {
    display: flex;
  }

  button {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.nav-bar {
  grid-area: nav-bar;
}

.workspace {
  background-color: #fff;
  display: grid;
  grid-area: workspace;
  grid-template-areas:
    'tab-bar edit-tree'
    'main-workspace edit-tree';
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
  margin-top: -42px;
  min-height: 0;
  padding-bottom: 10px;
  padding-top: 50px;

  &.business-intelligence {
    background: color2.$gray-900;
    padding: 30px 0 0;
  }

  .tab-bar {
    grid-area: tab-bar;
  }

  .edit-tree {
    grid-area: edit-tree;
  }

  .main-workspace {
    grid-area: main-workspace;
    min-height: 0;
    overflow-x: hidden;
  }

  &.nav-hidden {
    margin-left: 200px;
  }
}

.main-footer {
  grid-area: footer;
  min-height: 38px;
  z-index: 999;

  .footer-portal {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: flex-end;
    width: 100%;

    a {
      color: #fff;
      text-decoration: none;
    }

    .tooltip-wrapper {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.logo-wrapper {
  width: 200px;
}

.tiny {
  .nav-bar,
  .logo-wrapper {
    width: 45px;
  }
}

.toggle-nav {
  border-radius: 15px;
  color: lightgray;
  cursor: pointer;
  font-size: 13px;
  margin-left: 15px;

  i {
    margin-right: 15 px;
  }

  &:hover {
    color: ghostwhite;
    transition: all 0.3s linear;
  }
}

.eflex-main-logo {
  cursor: pointer;
  height: auto;

  img {
    height: 3.125rem;
  }
}

.eflex-logo-black {
  height: 3.125rem;
}

.nav-tabs > li > a {
  color: color.adjust(color2.$gray-700, $lightness: 27%);
  outline: 0;
  padding: 0.5rem 1rem;
}

.model-specific {
  color: color2.$primary !important;

  @at-root th#{&}, & label, & .header-row, & .nested-header {
    color: color2.$primary !important;
  }

  input:not([type='checkbox']),
  select,
  input.is-valid:not([type='checkbox']) {
    border: 1px solid color2.$primary;
    color: color2.$primary;
  }

  .input-group-prepend {
    border-color: color2.$primary;
  }

  .ember-power-select-trigger:not([aria-disabled='true']) {
    border-color: color2.$primary;
    color: color2.$primary;
  }

  .eflex-checkbox input[type='checkbox'] + label::before {
    border: 1px solid color2.$primary;
  }
}

.no-javascript {
  color: '#FF0000';
  text-align: center;
}

.form-group .input-icon {
  display: none;
}

.form-group:has(input:read-only) .input-icon {
  display: block;
  font-size: 0.75rem;
  top: calc(50% - 0.5rem);
}
