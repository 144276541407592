.component-task-config-button {
  .limits {
    .form-group {
      margin-top: -25px;
    }

    label {
      font-size: 10px;
      text-transform: uppercase;
    }
  }
}
