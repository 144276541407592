@use 'sass:color';
@use '_color' as styles-color;
@use 'bootstrap';

.component-file-button {
  .file-upload {
    display: none;
  }

  .file-label {
    background: transparent !important;
    border: none;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    font-weight: bootstrap.$font-weight-light;
    margin: 0;
    padding: 0;
  }

  .file-plus,
  .file-placeholder {
    color: styles-color.$primary;
    line-height: 20px;
    user-select: none;
  }

  .file-plus {
    font-size: 28px;
    position: relative;
    top: 3px;
  }

  .file-placeholder {
    border-bottom: 1px solid styles-color.$primary;
    font-size: 20px;
    margin-left: 5px;
  }

  .file-label:hover {
    border-bottom: none;
    box-shadow: none;

    .file-placeholder,
    .file-plus {
      color: color.adjust(styles-color.$primary, $lightness: -5%);
    }

    .file-placeholder {
      border-bottom: 1px solid color.adjust(styles-color.$primary, $lightness: -5%);
    }
  }
}
