@use 'sass:map';
@use 'sass:color';
@use '_color' as color2;
@use '_mixins';
@use '_functions';
@use 'bootstrap';
@use 'ember-power-select/themes/bootstrap' as power-select-bootstrap;

@use 'ember-power-select' with (
  $ember-power-select-text-color: color2.$default-text,
  $ember-power-select-border-color: bootstrap.$input-border-color,
  $ember-power-select-highlighted-background: map.get(bootstrap.$theme-colors, 'light'),
  $ember-power-select-selected-background: color.adjust(map.get(bootstrap.$theme-colors, 'light'), $lightness: -5%),
  $ember-power-select-multiple-option-border: none,
  $ember-power-select-multiple-selection-background-color: transparent,
  $ember-power-select-focus-border-color: color2.$primary,
  $ember-power-select-focus-box-shadow: 0 0 3px color2.$primary,
  $ember-power-select-dropdown-box-shadow: none,
  $ember-power-select-placeholder-color: bootstrap.$input-placeholder-color,
  $ember-power-select-trigger-icon-color: power-select-bootstrap.$ember-power-select-trigger-icon-color,
  $ember-power-select-focus-outline: power-select-bootstrap.$ember-power-select-focus-outline,
  $ember-power-select-opened-border-radius: power-select-bootstrap.$ember-power-select-opened-border-radius,
  $ember-power-select-search-input-border-radius: power-select-bootstrap.$ember-power-select-search-input-border-radius,
  $ember-power-select-dropdown-margin: power-select-bootstrap.$ember-power-select-dropdown-margin,
  $ember-power-select-highlighted-color: power-select-bootstrap.$ember-power-select-highlighted-color,
  $ember-power-select-dropdown-border: power-select-bootstrap.$ember-power-select-dropdown-border,
  $ember-power-select-dropdown-contiguous-border: power-select-bootstrap.$ember-power-select-dropdown-contiguous-border);

.ember-power-select-trigger {
  border-radius: bootstrap.$input-border-radius;
  font-size: bootstrap.$input-font-size;
  line-height: bootstrap.$input-line-height;
  max-height: calc(bootstrap.$input-height * 2.5);
  min-height: bootstrap.$input-height;
  padding: bootstrap.$input-padding-y bootstrap.$input-padding-x;
  white-space: nowrap;

  &.form-control-sm {
    font-size: bootstrap.$input-font-size-sm;
    max-height: calc(bootstrap.$input-height-sm * 2.5);
    min-height: bootstrap.$input-height-sm;
    padding: bootstrap.$input-padding-y-sm bootstrap.$input-padding-x-sm;
  }

  &.form-control-lg {
    font-size: bootstrap.$input-font-size-lg;
    max-height: calc(bootstrap.$input-height-lg * 2.5);
    min-height: bootstrap.$input-height-lg;
    padding: bootstrap.$input-padding-y-lg bootstrap.$input-padding-x-lg;
  }

  .ember-power-select-selected-item {
    margin-left: 0;
  }
}

.ember-power-select-placeholder {
  font-style: italic;
  width: 100%;
}

.ember-power-select-selected-item {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ember-power-select-multiple-option {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.ember-power-select-multiple-remove-btn {
  float: right;
  margin-left: 5px;
}

.ember-power-select-trigger[aria-disabled='true'] {
  cursor: not-allowed;
  opacity: 0.5;
}

.ember-basic-dropdown-content {
  background-color: #f7f7f7;
  z-index: map.get(bootstrap.$zindex-levels, 'select-options');
}

/* stylelint-disable-next-line selector-class-pattern */
.ember-power-select-dropdown.ember-basic-dropdown-content--below {
  transform: translateY(-1px);
}

.ember-power-select.standard-select {
  min-width: 80px;
  vertical-align: middle;
  width: auto;

  .ember-power-select-trigger {
    display: flex;

    .ember-power-select-placeholder {
      display: inline;
    }

    span {
      align-self: center;
      vertical-align: middle;
    }
  }
}

.no-chrome-power-select.ember-power-select-dropdown {
  box-shadow: -5px 0 38px 0 rgba(color2.$gray-900, 0.25);
  height: 80%;
  min-width: 300px;

  .ember-power-select-options[role='listbox'] {
    height: calc(100% - 34px);
    max-height: calc(100% - 34px);
  }

  .ember-power-select-options {
    @include mixins.responsive-text(20px);

    color: color2.$default-text;

    .ember-power-select-group-name {
      font-weight: bold;
    }

    .ember-power-select-option {
      &:hover {
        background-color: color2.$gray-100;
      }
    }

    .ember-power-select-option[aria-selected='true'] {
      background-color: color2.$info;
    }

    .ember-power-select-option[aria-current='true'] {
      color: color2.$default-text;
    }
  }
}

.is-invalid {
  .ember-power-select-trigger {
    border: 1px solid #d81422;
  }

  .ember-power-select-search input {
    border: initial !important;
  }
}

.button-power-select {
  margin-right: 10px;
  margin-top: 5px;
  width: fit-content !important;
}

.ember-model-select-dark {
  background-color: transparent;
  border: 1px solid color2.$gray-600;

  &:focus {
    background-color: transparent;
  }

}
