.component-jem-note {
  border-bottom: 3px solid #c5c6c8;
  margin-bottom: 15px;
  padding: 15px;

  .jem-note-minor {
    color: gray;
    display: inline-block;
    margin-left: 5px;
  }

  .jem-note-user {
    display: inline-block;
  }
}
