@use '../_color';

.print-label-modal {
  .gray-0 {
    div {
      justify-content: flex-start;
    }

    h3 {
      margin-top: 0;
    }

    label {
      color: color.$light-text;
      font-size: 14px;
      margin-right: 10px;
      padding: 0;
      width: 70px;
    }

    input {
      color: color.$default-text;
      font-size: 16px;
    }
  }
}
