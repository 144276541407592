@use '../_color';

.code-confirmation-modal {
  .is-invalid {
    border-color: color.$danger;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%);
  }

  .code-confirmation-note {
    height: 166px;
  }

  .gray-0 {
    display: block;
    margin: 0;
    padding: 20px;

    .user-datetime {
      color: color.$light-text;
      font-size: 0.75rem;
      position: absolute;
      right: 2rem;
    }

    label {
      color: color.$light-text;
      font-size: 0.875rem;
    }

    div {
      display: block;
    }

    div:not(:last-child) {
      margin-bottom: 0;
    }

    div:first-child {
      margin-bottom: 12.5px;
      margin-top: -6px;
    }

    .ember-power-select-trigger {
      margin-bottom: 0 !important;
    }

    .ember-power-select-multiple-option {
      color: color.$default-text;
      font-size: 16px;
      margin: 2px 0 0 5px;
      padding: 0;
    }

    textarea {
      color: color.$default-text;
      font-size: 16px;
      height: 194px;
      resize: none;
    }

    .code-confirmation-wrapper {
      label {
        margin-bottom: 8px;
        padding: 0;
      }
    }
  }
}
