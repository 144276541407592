@use 'sass:color';
@use '../_color' as color2;

.repair-page {
  .part-info {
    margin-top: -6px;
  }

  .repair-cog {
    color: #8b8b8b;

    i {
      cursor: pointer;
      font-size: 24px;
    }

    i:hover {
      color: color.adjust(#8b8b8b, $lightness: -10%);
    }
  }

  .serial {
    white-space: nowrap;
  }

  .overall-build-status {
    white-space: nowrap;
  }

  .search-input {
    width: 95%;
  }

  .padded-top {
    padding-top: 1%;
  }

  .padded-sides {
    padding-left: 35px;
    padding-right: 35px;
  }

  .padded-sides-boot {
    padding-left: 20px;
    padding-right: 20px;
  }

  .repair-label {
    color: #8b8b8b;
    font-size: 16pt;
    vertical-align: bottom;
  }

  .repair-val {
    color: #4f4b47;
    font-size: 26pt;
    font-weight: 600;
    line-height: 1.2;
    margin-left: 0.5rem;
    vertical-align: bottom;
  }

  .underline-red {
    position: relative;

    &::after {
      background: #ea1625;
      bottom: -2px;
      content: '';
      display: block;
      height: 5px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  .underline-green {
    border-bottom: 5px solid;
    border-bottom-color: color2.$success;
  }

  .disabled {
    opacity: 0.5;
  }

  .build-status-grid {
    table {
      border: 1px solid color2.$gray-600;
    }

    tbody {
      border-bottom: rgb(221, 221, 221);
      border-bottom-style: solid;
      border-bottom-width: 1px;
    }

    th.repair-header {
      background-color: color2.$light;
      border-right-color: color2.$light;
      color: #8b8b8b;
      font-size: 10pt;
      font-weight: 600;
      height: 36px;
      letter-spacing: 0.5px;
      padding: 0;
      text-align: left;
      text-shadow: 0 1px 0 #fff;
      text-transform: uppercase;
      vertical-align: middle;

      &.timestamp-header,
      &.status-header,
      &.action-header {
        width: 180px;
      }

      &.task-header,
      &.status-header,
      &.action-header {
        padding-left: 1.2em;
      }
    }

    td {
      border: none;
    }

    .area,
    .group,
    .station {
      td {
        padding: 2px 0;
      }
    }

    .area {
      background: #4f4c47;
      cursor: pointer;
      text-align: left;
    }

    td.area {
      padding: 0;
    }

    td.group {
      padding: 0;
    }

    .group {
      background: #736e68;
      cursor: pointer;
      text-align: left;

      .group-icon-sm {
        margin-left: 1.4em;
        vertical-align: 0;
      }
    }

    .station {
      background: #b3b2ad;
      cursor: pointer;
      text-align: left;

      .task {
        width: 100%;
      }

      .tree-genericstation-icon {
        margin-left: 2.6em;
      }
    }

    td.station {
      padding: 0;
    }

    .inline {
      display: inline-block;
      margin: auto 5px;
      vertical-align: -1px;
    }

    .inline.icon {
      margin: 6px;
      vertical-align: middle;
    }

    .repair-btn {
      background: #736e68;
      box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 50%), inset 0 1px 1px rgba(255, 255, 255, 80%);
      color: #fff;
      font-size: 24pt;
      font-weight: normal;
      width: 100%;
    }

    .repair-btn:hover {
      background: #4f4c47;
    }

    .task {
      color: color2.$default-text;
      font-size: 12pt;

      &:hover {
        background: color2.$default-hover;

        .status-cell.repaired > div,
        .repair-btn-cell.repaired {
          background: color2.$success;
          background: color.adjust(color2.$success, $lightness: -7%);
          color: #000;
        }

        .status-cell.rework,
        .status-cell.rejected,
        .repair-btn-cell.rework,
        .repair-btn-cell.rejected {
          &,
          > div {
            background: #da1724;
          }
        }
      }

      td {
        padding: 0;
      }
    }

    .toggle-label {
      color: #fff;
      font-size: 12pt;
      margin-left: 0.2em;
      vertical-align: 2px;
    }

    .status-cell {
      text-align: left;
      vertical-align: middle;

      > div {
        height: 55px;
        padding: 0 0.75em;
      }

      &.repaired > div {
        background: color2.$success;
        color: #000;
        width: 100%;

        span.repaired-timestamp {
          white-space: nowrap;
        }
      }

      &.rejected,
      &.rework {
        > div {
          background-color: #ea1625;
        }
      }
    }

    .rejected,
    .rework {
      color: #fff;
    }

    .repair-btn-cell {
      background: color2.$light;
      height: 55px;
      padding: 0;

      &.good,
      &.repaired {
        background: color2.$success;
        color: #000;
      }

      &.rework,
      &.rejected {
        background: #ea1625;
      }
    }

    .task-name-cell {
      text-align: left;

      > div {
        display: inline-block;

        &.task-name {
          vertical-align: 5px;
        }
      }

      .task-icon.inline {
        background-position: center;
        background-size: contain;
        height: 25px;
        margin-left: 5.3em;
        width: 25px;
      }
    }

    .repair-caret {
      color: #fff;
      margin-left: 5px;
      width: 8px;
    }

    .inline-icon {
      padding-left: 20%;
    }

    .timestamp-cell {
      text-align: left;
    }

    .status-additional {
      i {
        margin-right: -0.25em;
        margin-top: 0.25em;
      }
    }

    .status-btn {
      background: white;
      border: 2px solid color2.$light-text;
      border-radius: 0;
      font-size: 0.9em;
      font-weight: 700;
      line-height: 1.6;
      opacity: 0.5;
      padding: 0 0.3em;

      &:hover {
        background: color.adjust(white, $lightness: -15%);
      }
    }
  }
}
