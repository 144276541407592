.complex-option-builder {
  .query-builder {
    .rules-group-container,
    .rule-container,
    .rule-placeholder {
      background: rgba(243, 241, 242, 90%);
      border: 1px solid #aaa9a9;
      border-radius: 3px;
      margin: 4px 0;
      padding: 10px;
    }

    .rules-group-container {
      background: rgba(215, 214, 215, 50%);
      padding: 10px;
      padding-bottom: 6px;
      width: 100%;
    }

    .rule-filter-container,
    .rule-operator-container {
      select {
        padding-top: 0;
      }
    }

    .rules-list > ::after,
    .rules-list > ::before {
      border-color: #999;
    }

    .btn {
      background-color: #a7a6a6;
      border-radius: 2px;
      font-size: 10pt;
      padding: 2px 12px;

      .glyphicon {
        display: none;
      }
    }

    .btn-primary:active,
    .btn-primary.active,
    .open > .dropdown-toggle.btn-primary {
      background-color: #2f2f2f;
      border-color: #6a6a6a;
      color: white;
    }

    .btn-primary {
      background-color: #999;
      border-color: #6a6a6a;
      color: #1a3016;
      margin-top: 4px;

      .active {
        background-color: #474747;
        border-color: #6a6a6a;

        &:hover {
          background-color: #000;
          border-color: #6a6a6a;
        }

        .disabled {
          background-color: #c7c2c2;
          border-color: #a7a4a4;

          &:hover {
            background-color: #c7c2c2;
            border-color: #a7a4a4;
          }
        }
      }

      .disabled {
        background-color: #c7c2c2;
        border-color: #a7a4a4;

        &:hover {
          background-color: #c7c2c2;
          border-color: #a7a4a4;
        }
      }
    }

    .btn-success {
      border-color: #6a6a6a;
      margin-top: 4px;

      &:hover {
        background-color: #474747;
        border-color: #6a6a6a;
      }

      &:focus {
        background-color: #474747;
        border-color: #6a6a6a;
      }
    }

    .btn-danger {
      border-color: #6a6a6a;
      margin-right: 3px;
      margin-top: 4px;

      &:hover {
        background-color: #474747;
        border-color: #6a6a6a;
      }
    }
  }

  .rule-actions {
    float: right;
  }
}
