@use 'sass:color';
@use '_color' as color2;
@use 'bootstrap';

.eflex-badge-tooltip-link {
  margin-bottom: 4px;

  a {
    color: #fff !important;
    cursor: pointer;
    text-decoration: underline !important;
  }

  a:hover {
    color: color.adjust(#fff, $lightness: -10%) !important;
    text-decoration: none !important;
  }

  a:visited {
    color: #fff;
    text-decoration: none;
  }
}
