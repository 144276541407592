@media print {
  @page {
    size: landscape;
  }

  .workspace {
    height: inherit !important;
    margin-left: 0;
  }

  body,
  .ember-grid-component,
  .workspace {
    overflow: visible !important;
  }
}
