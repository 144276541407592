@use '_color';
@use 'bootstrap';
@use 'sass:map';

$modal-header-height: 40px;
$modal-footer-height: 40px;
$modal-outside-height: $modal-header-height + $modal-footer-height;

.modal-backdrop {
  z-index: map.get(bootstrap.$zindex-levels, 'modal-backdrop');
}

.modal {
  z-index: map.get(bootstrap.$zindex-levels, 'modal');
}

.standard-modal {
  .modal-content {
    min-height: calc(var(--bs-modal-width) / 1.5);

    .modal-header {
      align-items: center;
      background-color: bootstrap.$modal-footer-bg;
      border-bottom: none;
      color: color.$light-text;
      display: flex;
      height: $modal-header-height;

      h4 {
        font-size: 24px;
        font-weight: bootstrap.$font-weight-light;
      }
    }

    .modal-footer {
      align-items: flex-end;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;

      .btn {
        font-size: map.get(bootstrap.$font-sizes, 4);
        margin-left: 0;
      }

      > :not(:last-child) {
        margin-right: 0.25rem;
      }
    }
  }

  .modal-dialog {
    &.modal-xl {
      height: 90%;
    }
  }
}
