.component-inputs-stroke-select {
  align-items: center;
  display: flex;
  width: 100%;

  .stroke-style-select {
    margin-right: 10px;
    width: 100%;
  }

  .stroke-width {
    align-items: center;
    display: flex;
    position: relative;

    .stroke-width-input {
      width: 80px;
    }

    label {
      position: absolute;

      &.ones {
        left: 1.5rem;
      }

      &.tens {
        left: 2rem;
      }

      &.disabled {
        opacity: 0.5;
      }
    }
  }
}
