@use 'sass:color';
@use '_color' as color2;
@use 'bootstrap';
@use 'sass:map';

.component-jem-task-grid {
  height: 100%;

  .jem-task-grid-container {
    background-color: color2.$gray-0;
    height: 100%;
    overflow-x: hidden;
    position: relative;

    /* stylelint-disable-next-line selector-class-pattern */
    .JCLRgrips {
      position: fixed;
      z-index: map.get(bootstrap.$zindex-levels, 'title-bar');
    }

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
      border-style: inherit;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .scroll-container .ps__rail-y {
    z-index: 1002;
  }

  .jem-task-grid {
    &.table {
      border-collapse: separate;
      border-spacing: 0;

      td {
        vertical-align: middle;
      }
    }

    > thead {
      color: color2.$gray-200;
      font-size: 20px;
      font-weight: normal;
      position: relative;
      text-transform: uppercase;
      z-index: map.get(bootstrap.$zindex-levels, 'table-header');

      tr {
        th {
          background-color: color2.$gray-900;
          border-bottom: none;
          border-left: solid color2.$gray-600 2px;
          border-top: none;
          font-weight: normal;
          margin-top: -5px;
          padding-top: 9px;
          position: sticky;
          top: 0;

          &:first-child {
            border-left: none;
          }
        }

        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    > tbody {
      td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      tr > td {
        border-top: none;
      }
    }
  }

  .jem-step {
    text-align: left;
  }

  .jem-type {
    text-align: center;
  }

  td.jem-description {
    line-height: 1;
  }

  .jem-description {
    display: table-cell;
    word-wrap: break-word;

    .task-icon {
      margin: auto 0;
    }
  }
}
