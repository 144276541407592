@use '../_color';

.img-placeholder {
  max-height: 506px;
  max-width: 900px;
  padding-top: 10px;
}

.user-timeout-length-label {
  padding-top: 15px;
}
