@use '_color';

$levels: success, danger, warn, info;

$disabled-opacity: 0.5;

$table-body: #fff;
$table-border: 1px solid color.$gray-400;

$footer-height: 30px;
