@use 'sass:color';
@use 'sass:map';
@use 'bootstrap';
@use '_color' as color2;

$popup-background: #333;
$popup-text: #9e9e9e;

.component-tree-edit-item {
  .locked {
    .tree-menu-item {
      .lock {
        display: none;
      }

      &:hover {
        .lock {
          display: inline-block;

          &:hover,
          .popup-content {
            display: inline-block;
          }
        }

        .tree-menu-item-controls {
          display: none;
        }
      }
    }
  }

  .lock {
    display: none;
  }

  .holder {
    width: calc(100% - 30px);

    .app-tree-child.plant-task {
      align-items: center;
      display: flex;
    }
  }

  .station-icon {
    min-width: 30px;
  }

  .active:not(.tree-task) {
    background: rgba(color2.$primary, 0.5);
    border-radius: 3px;

    &:hover {
      background: rgba(color2.$primary, 0.5);
    }
  }

  .popup-content {
    background-color: $popup-background;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 30%);
    color: $popup-text;
    display: none;
    height: 45px;
    margin-top: 0;
    max-height: 700px;
    min-width: 0;
    padding: 10px;
    position: absolute;
    right: 20px;
    text-align: right;
    top: -14px;
    z-index: map.get(bootstrap.$zindex-levels, 'popover');

    i {
      background: color.adjust($popup-background, $lightness: 10%);
      background: linear-gradient(to bottom, color.adjust($popup-background, $lightness: 10%) 0%, $popup-background 100%);
      border: 1px solid color.adjust($popup-background, $lightness: -10%);
      border-radius: 3px;
      color: $popup-text;
      display: inline-block;
      font-size: 18px;
      margin: 0 3px;
      margin-right: 10px;
      padding: 3px 5px;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 80%);

      &:hover {
        color: color.adjust($popup-text, $lightness: 40%);
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  .popup-content::after {
    border-color: transparent #34302b;
    border-style: solid;
    border-width: 7px 0 7px 7px;
    content: '';
    display: block;
    left: auto;
    margin-left: -7px;
    position: absolute;
    right: -7px;
    top: 14px;
    width: 0;
    z-index: map.get(bootstrap.$zindex-levels, 'popover');
  }

  .tree-item-label {
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
