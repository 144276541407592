@use '_color';

$tab-height: 44px;

.component-jem-instructions {
  background: color.$gray-0;
  height: 100%;
  overflow: hidden;
  pointer-events: all;

  .eflex-tabs {
    background-color: color.$gray-900;
    border-bottom: none;
    color: color.$gray-200;
    flex-direction: row;
    height: $tab-height;
    margin-bottom: 0;
    overflow: hidden;

    li {
      color: color.$gray-200;
      display: inline;
      font-size: 20px;
      font-weight: normal;
      line-height: 46px;
      margin-left: 15px !important;
      margin-right: 15px !important;
    }

    a {
      color: color.$gray-200 !important;

      &.active {
        border-color: color.$info;
        color: color.$info !important;
      }
    }

    .nav-link {
      display: inline;
    }
  }

  .tab-content,
  .tab-pane.active {
    height: 100%;
  }

  .tab-editing {
    display: flex;
    padding-top: 5px;

    .tab-add-icon,
    .tab-edit-icon,
    .tab-delete-icon {
      cursor: pointer;
      font-size: 25px;
      padding-right: 15px;

      &:hover {
        color: color.$warning;
      }
    }
  }

  .gutter-vertical {
    border-bottom: 9px solid transparent;
    border-top: 9px solid transparent;
  }

  .full-screen-toggle {
    background: url('/images/fullscreen-icon.svg') no-repeat center;
    cursor: pointer;
    height: 20px;
    margin: 12px;
    vertical-align: middle;
    width: 20px;

    &.hidden {
      display: none;
    }
  }

  .jem-image-container {
    position: relative;

    img {
      display: block;
      height: 100%;
      margin: auto;
      object-fit: contain;
      width: 100%;
    }

    .jem-no-data img {
      height: 80%;
    }

    &.full-screen {
      background-color: #212529;
      height: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1000;
    }

    &.full-screen .full-screen-toggle {
      background: url('/images/fullscreenexit-icon.svg') no-repeat center;
      cursor: pointer;
      height: 20px;
      left: calc(100% - 20px - 12px - 12px);
      margin: 12px;
      position: absolute;
      top: 0;
      width: 20px;
      z-index: 1001;
    }

    object,
    iframe,
    .threed-viewer {
      height: 100%;
      width: 100%;
    }
  }
}
