.component-bi-quality-p-chart {
  .total-cycles-label-wrapper {
    .label {
      font-size: 1rem;
    }

    .total-cycles {
      font-size: 2.5rem;
    }

    .total-rejects {
      font-size: 1.75rem;
    }
  }
}
