@use 'bootstrap';

.component-task-type-select {
  .add-task {
    background-color: bootstrap.$primary;
    cursor: pointer;
  }

  .task-dropdown {
    min-width: 300px;
  }

  .ember-power-select-options {
    max-height: 100%;
  }
}
