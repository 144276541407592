@use 'bootstrap';

.component-work-instruction-editor-files {
  .wie-spinner-overlay {
    background-color: black;
    height: 100%;
    opacity: 0.5;
    position: relative;
    width: 100%;
    z-index: 1000;

    .spinner {
      left: 45%;
      z-index: 1010 !important;
    }
  }

  .file-body {
    background-color: #fff;
    border: 1px solid bootstrap.$dark;
    border-radius: 4px;
    height: 100%;
    overflow: auto;

    ul {
      li {
        border: none;
        border-radius: 0;
        cursor: pointer;
        font-size: 1rem;
        line-height: 1rem;
      }
    }
  }

  .file-footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    z-index: 10;

    .x-file-input {
      label {
        margin-bottom: 0;
      }
    }
  }
}
