@use 'sass:color';
@use '../_color' as color2;
@use '../_mixins';
@use 'bootstrap';

.no-bom-configuration {
  @include mixins.responsive-text(36px);

  align-items: center;
  color: #868585;
  display: flex;
  height: calc(100% - 60px);
  justify-content: center;
  padding: 25px;
  text-align: center;
  width: 100%;
}

.bom-source-tasks-popup {
  .list-group-item {
    padding: 0.5rem 1rem;

    .task-icon {
      background-size: 20px 20px;
      height: 20px;
      width: 20px;
    }
  }
}

.component-options-panel {
  .component-buttons {
    background: color.adjust(color2.$gray-700, $lightness: 20%);
    height: 35px;
    text-align: right;
    width: 100%;
  }

  .app-component {
    .selected {
      background: #ededed;
    }
  }
}
