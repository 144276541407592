@use '_color' as color2;

.component-work-instruction-editor-tool-properties-lines {
  .panel-radio {
    label {
      color: color2.$gray-700;
      font-weight: normal;
    }

    .horizontal-radio {
      margin-right: 0;
    }
  }
}
