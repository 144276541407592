@use '_color';

.component-jem-task-specific-display-torque-details {
  .bootstrap-table {
    background-color: color.$secondary;
  }

  .bootstrap-table .form-row.bolt-row {
    background-color: color.$gray-400;

    &.bolt-started {
      background-color: color.$non-photo-blue;
    }

    &.bolt-good {
      background-color: color.$success;
    }

    &.bolt-rejected {
      background-color: color.$danger;
    }
  }

  .torque-col {
    min-width: 130px;
  }

  .tightening-col {
    max-width: 104px;
  }
}
