.standard-modal.copy-model-modal {
  label {
    min-width: 20%;
    width: 10%;
  }

  #copy-model-list {
    display: inline-block;
    width: 80%;
  }
}
