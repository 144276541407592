@use '_color';
@use 'bootstrap';
@use 'sass:map';

.component-work-instruction-editor-tool-properties-icons {
  .icons-content-wrapper {
    height: calc(100% - 40px);

    .title {
      padding-top: 4px;
    }

    .icons-header {
      margin-bottom: 5px;

      .square-color-picker {
        min-width: 0;
      }
    }

    .icon-scroll-container {
      height: calc(100% - 530px);
      min-height: 130px;
    }
  }

  .wie-icon-delete {
    color: color.$light-text;
    font-size: 10px;
    margin-right: 3px;
    margin-top: 2px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: map.get(bootstrap.$zindex-levels, 'wie-delete-icon');
  }

  .wie-icon-delete::before {
    content: 'x';
  }

  .icon-adder {
    background-color: #f0f0f0;
    height: 45px;
    padding-top: 12.5px;
    width: 100%;

    .svg-text {
      bottom: 2px;
      color: color.$light-text;
      font-size: 12px;
      font-style: italic;
      left: 5px;
      position: relative;
    }
  }

  .panel-radio label {
    color: color.$gray-700;
    font-weight: normal;
  }

  .row {
    margin-left: -5px;
    margin-right: -5px;
  }

  [class*='col-'] {
    padding-left: 5px;
    padding-right: 5px;

    .btn {
      background-color: #fff;
      border-color: #b3b3b3;
      display: inline-block;
      height: 71.33px;
      margin-bottom: 10px;
      padding: 8px 0 0;
      pointer-events: all;
      width: 100%;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        background-color: #d9d9d9;
      }

      &.active {
        background-color: #d9d9d9;
        border: #ffb200 1px solid;
      }

      img {
        height: 53px;
        position: relative;
        width: 53px;
      }
    }
  }

  // Have to use !important here because of Bootstrap's secificity in regards to button.btn
  .grab {
    cursor: grab !important;

    &.grabbing,
    &:active {
      cursor: grabbing !important;
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed !important;
    }
  }
}
