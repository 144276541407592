.component-radio-group {
  .vertical-radio {
    display: block;
    margin: 10px 0;

    input {
      margin-right: 7px;
    }
  }

  .horizontal-radio {
    margin-right: 30px;
  }
}
