.component-jem-dynamic-instruction {
  display: flex;
  height: 100%;
  width: 100%;

  .work-instruction-display {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;

    .canvas-container {
      align-items: center;
      display: flex;
      height: 100% !important;
      justify-content: center;
      width: 100% !important;
    }

    canvas {
      left: unset !important;
      top: unset !important;
    }
  }
}
