@use '_color';
@use 'bootstrap';

.component-jem-no-data {
  height: 100%;

  .jem-no-data {
    background-color: color.$gray-200;
    border-radius: 0.25em;
    color: color.$gray-400;
    font-size: 30px;
    font-weight: bootstrap.$font-weight-light;
    height: 80%;
    margin: 20px;
    max-height: 300px;
    overflow: hidden;
    padding: 20px;
    position: relative;
    text-align: center;

    &.full-screen {
      margin-top: 38px;
    }

    img {
      height: 100%;
      max-height: 200px;
    }
  }
}
