@use 'sass:color';
@use 'bootstrap';

.component-inputs-color-opacity {
  align-items: center;
  display: flex;
  width: 100%;

  label {
    color: color.adjust(white, $lightness: -70%);
    font-size: 14px;
    font-weight: bootstrap.$font-weight-light;
    margin-bottom: 0;
  }

  .opacity {
    margin-right: 10px;
    width: 100%;
  }

  .square-color-picker {
    height: 28px;
    max-width: 28px;
    min-width: 28px;
    width: 28px;
  }
}
