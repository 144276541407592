@use '_color' as color2;
@use 'sass:color';

.component-work-instruction-editor-tool-properties-shape {
  .shape-button {
    align-items: center;
    background-color: white;
    border: 1px color.adjust(white, $lightness: -30%) solid;
    color: color.adjust(white, $lightness: -65%);
    display: flex;
    justify-content: center;
    padding: 5px;
    width: 36px;

    &:hover {
      background-color: color.adjust(white, $lightness: -15%);
    }

    &:active {
      box-shadow: none;
    }

    &.selected {
      background-color: color.adjust(white, $lightness: -15%);
      border: color2.$warning 1px solid;
    }

    .rect {
      background: color.adjust(white, $lightness: -65%);
      height: 20px;
      width: 20px;
    }

    .ellipse {
      background: color.adjust(white, $lightness: -65%);
      border-radius: 40px;
      height: 20px;
      width: 20px;
    }

    .triangle {
      border-bottom: 20px solid color.adjust(white, $lightness: -65%);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      height: 0;
      width: 0;
    }
  }
}
