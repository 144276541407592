@use '_color' as color2;
@use 'sass:color';

.component-work-instruction-editor-tool-buttons {
  .editor-controls {
    flex-direction: column;
    height: 100%;
    padding-left: 18px;
    padding-top: 18px;
    width: 90px;

    /* stylelint-disable-next-line selector-class-pattern */
    .ps__scrollbar-y-rail {
      width: 8px;
    }

    .button-controls {
      overflow-y: auto;
      width: 68px;
    }

    .button-group {
      margin-bottom: 10px;
    }

    .has-upload-image {
      .x-file-input {
        display: flex;
        width: 60px;
      }
    }

    button {
      align-items: center;
      background-color: white;
      border: 1px color.adjust(white, $lightness: -30%) solid;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      font-size: 9px;
      height: 40px;
      justify-content: center;
      margin-bottom: 3px;
      text-transform: uppercase;
      width: 60px;

      img {
        display: block;
        height: 20px;
        margin: 0 auto 2px;
        padding-top: 2px;
        width: 26px;
      }

      svg {
        color: color2.$gray-700;
      }

      &:hover {
        background-color: color.adjust(white, $lightness: -15%);
      }

      &:focus {
        outline: none;
      }

      &.selected {
        background-color: color.adjust(white, $lightness: -15%);
        border: color2.$primary 1px solid;
      }
    }

    label:hover button {
      background-color: color.adjust(white, $lightness: -15%);
    }

    label:active button {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 12.5%);
    }

    i {
      color: color2.$gray-700;
      font-size: 20px;
    }

    .additional-controls {
      align-items: center;
      display: flex;
      height: 40px;

      label {
        font-size: 10px;
        text-transform: uppercase;
      }
    }
  }
}
