@use '_color';
@use 'bootstrap';
@use '_variables';
@use 'sass:math';
@use 'sass:map';

$nested-header-height: 0.9375rem;

.bootstrap-table {
  display: flex;
  flex-direction: column;
  padding-left: math.div(bootstrap.$grid-gutter-width, 2);
  padding-right: math.div(bootstrap.$grid-gutter-width, 2);

  .table-position {
    height: 50px;
    pointer-events: none;
    width: 100%;

    &.table-top {
      top: 30px;
    }

    &.table-bottom {
      bottom: 100%;
    }
  }

  .fade-table {
    height: 75px;
    left: -5px;
    pointer-events: none;
    position: absolute;
    transition: opacity 0.2s ease;
    width: calc(100% + 15px);

    &.fade-top {
      background-image: linear-gradient(white, transparent);
      top: 30px;
    }

    &.fade-bottom {
      background-image: linear-gradient(transparent, white);
      bottom: 0;
    }
  }

  .form-row.header-row {
    align-items: center;
    color: color.$gray-600;
    font-size: map.get(bootstrap.$font-sizes, 5);
    font-weight: bold;
    height: auto;
    line-height: 1;
    min-height: 1.875rem;
    padding-bottom: 0;
    padding-top: 0;
    text-transform: uppercase;

    .subheader {
      font-size: 0.75rem;
      text-transform: capitalize;
    }

    &>div {
      align-items: center;
      padding-bottom: 5px;
    }

    &>div:not(.border-0) {
      &::after {
        border-bottom: variables.$table-border;
        content: '';
        display: block;
        height: 2px;
        margin-top: 0.5rem;
      }
    }
  }

  .nested-header {
    align-items: center;
    color: color.$light-text;
    font-size: map.get(bootstrap.$font-sizes, 6);
    height: $nested-header-height;
    padding-bottom: 0;
    padding-top: 0;
    text-transform: uppercase;
  }

  .form-row {
    background-color: variables.$table-body;
    padding-bottom: 8px;
    padding-top: 8px;

    .form-group {
      margin-bottom: 0;
    }

    .form-row {
      background-color: inherit;

      &:last-of-type {
        padding-bottom: 0 !important;
      }
    }

    &.selected {
      background-color: color.$light;
    }

    &:hover:not(.header-row, .pagination-row) {
      background-color: color.$default-hover;

      .form-row {
        background-color: inherit;
      }
    }

    &.no-hover-row:hover {
      background-color: variables.$table-body !important;
    }
  }

  .icon-col {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    min-height: bootstrap.$input-height-sm;
    width: 1rem;
  }

  .align-nested-header {
    margin-bottom: auto;
    margin-top: $nested-header-height;
  }

  .form-row.pagination-row {
    background-color: color.$light;
    border-top: variables.$table-border;
    color: color.$light-text;
  }
}
