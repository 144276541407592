@use '_color';
@use '_variables';
@use 'bootstrap';
@use 'sass:map';

$header-height: 30px;
$border-width: 12px;

.side-panel {
  box-shadow: -5px 0 38px rgba(color.$gray-400, 0.25);
  height: calc(100% - 114px);
  position: fixed;
  right: 0;
  top: 76px;
  width: 62%;
  z-index: map.get(bootstrap.$zindex-levels, 'side-panel');

  .collapse-panel-arrow {
    align-items: center;
    color: color.$gray-600;
    cursor: pointer;
    left: -15px;
    position: absolute;
    top: 0;
    width: 1rem;
  }
}

.side-panel-footer {
  width: 100%;

  .square-button {
    @extend .btn-outline-gray-700, .bg-white;

    font-weight: bold;

    &:hover {
      @extend .bg-gray-700;
    }
  }

  .panel-buttons {
    padding-top: 2px;
  }
}

.side-panel-footer + .side-panel-content {
  border-bottom: 0;
}
