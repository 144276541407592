@use '_color';

.offcanvas-top {
  --bs-offcanvas-height: fit-content;
}

.component-criteria-visor {
  .search-criteria {
    background-color: rgba(color.$gray-900, 75%);
    left: 0;
    width: 100%;

    &.eflex-criteria-visor {
      .visor-search {
        margin: (-10px) 0 10px 0;
      }

      button.btn-close {
        opacity: 1;
        position: absolute;
        right: 10px;
        top: 8px;
      }

      .criteria-auto-grid {
        column-gap: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 10px;
      }

      @media (max-width: 942px) {
        .criteria-auto-grid {
          grid-template-columns: 1fr;
        }
      }

      .filter-container {
        background: color.$gray-0;
        border-radius: 3px;
        box-shadow: 2px 2px 3px;
        color: color.$gray-700;
        margin-bottom: 10px;
        min-width: 300px;
        padding: 10px;

        .search-box {
          background: color.$gray-0;
          padding: 10px 0;

          input {
            width: 95%;
          }
        }

        h3 {
          color: color.$gray-700;
          margin: 0 0 15px;
          padding: 0;
        }

        label {
          color: color.$gray-700;
        }
      }
    }
  }

  .eflex-criteria-opener {
    align-items: center;
    color: color.$gray-600;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    width: fit-content;

    &:hover {
      opacity: 0.8;
    }
  }

  .date-picker-input-button {
    height: 30px;
  }
}
