@use '_color';
@use '_variables';
@use 'bootstrap';
@use 'sass:map';

.table {
  --bs-table-bg: inherit;
  --bs-table-color: inherit;
}

.eflex-table {
  table-layout: fixed;

  thead {
    background-color: color.$light;
    border: variables.$table-border;
    color: color.$light-text;
    font-size: map.get(bootstrap.$font-sizes, 4);

    th {
      border-bottom: variables.$table-border;
      border-top: variables.$table-border;
      font-weight: normal;
      text-align: left;
      text-transform: uppercase;
      vertical-align: middle;
    }
  }

  tbody {
    background-color: variables.$table-body;
    border: variables.$table-border;

    .form-row {
      height: 34px;
    }

    tr {
      .selected {
        background: color.$gray-100;

        i {
          visibility: visible !important;
        }
      }
    }

    td {
      border-top: 0;
      vertical-align: middle;
    }
  }

  .icon-col {
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
    padding-top: 8px;
    width: 30px;
  }
}
