@use '_color';

.repair-panel.side-panel {
  .side-panel-body {
    padding: 0;

    & > .col {
      padding: 0;
    }
  }

  .task-name {
    width: fit-content;

    .task-icon {
      height: 20px;
      width: 20px;
    }
  }

  .task-status,
  .marriage-status {
    text-transform: uppercase;

    &.not-required {
      color: color.$light;
    }

    &.success {
      color: color.$success;
    }

    &.danger {
      color: color.$danger;
    }

    &.warning {
      color: color.$warning;
    }
  }
}
