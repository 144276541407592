.component-work-instruction-editor-tool-properties-text {
  .font-size-input {
    width: 60px;
  }

  .right-align {
    display: flex;
    justify-content: flex-end;
  }

  .text-opacity-select {
    width: 100px;
  }

  .stroke-width-input {
    margin-right: 12px;
    width: 60px;
  }

  .stroke-property-labels {
    display: flex;
  }

  .width-label {
    font-size: 9px;
    left: 72px;
    position: relative;
    top: 10px;
    width: auto;
  }

  .stroke-opacity-label {
    font-size: 9px;
    left: 114px;
    position: relative;
    top: 10px;
    width: auto;
  }
}
