@use '../_color';
@use 'bootstrap';
@use 'sass:map';

.oee-config-button {
  cursor: pointer;
}

.oee {
  color: color.$gray-100;
  grid-area: no-chrome-workspace;

  .oee-header {
    height: 50px;
    width: 100%;
    z-index: map.get(bootstrap.$zindex-levels, 'title-bar');

    .oee-header-content {
      color: color.$gray-0;
      display: flex;
      justify-content: space-between;
      padding-bottom: 4px;
      padding-top: 4px;
      position: fixed;
      width: 100%;

      .welcome-message {
        color: color.$gray-200;
        font-size: 1.125rem;
        font-style: italic;
        height: 22px;
        letter-spacing: -0.89px;
        line-height: 22px;
        text-align: right;
      }
    }
  }

  .oee-content {
    height: calc(100% - 100px);
    padding-left: 30px;
    padding-right: 30px;

    .oee-shift-info {
      color: color.$gray-0;
      display: flex;
      height: 60px;
      justify-content: space-between;
      letter-spacing: -1.09px;
      margin-bottom: 6px;
      margin-top: 4px;
      width: 100%;

      .oee-current-schedule {
        font-size: 1.875rem;
        height: 36px;
        line-height: 36px;
        margin: 11px 0 13px;

        .no-schedule {
          color: color.$gray-100;
        }
      }

      .oee-current {
        display: flex;

        .date {
          color: color.$gray-100;
          font-size: 1.875rem;
          height: 36px;
          line-height: 36px;
          margin: 11px 15px 13px 0;
        }

        .time {
          font-size: 3.125rem;
          height: 60px;
          line-height: 60px;
        }
      }
    }

    .oee-actions {
      background-color: color.$gray-900;
      border: 1px solid color.$gray-100;
      display: flex;
      height: 174px;
      justify-content: space-between;
      width: 100%;

      &.disabled {
        .action {
          cursor: not-allowed;
        }
      }

      .action {
        @extend .col-2;

        cursor: pointer;
        height: 174px;
        margin: 0;
        padding: 0;
        text-align: center;

        .label {
          color: color.$gray-100;
          font-size: 18px;
          height: 22px;
          letter-spacing: 0.01px;
          line-height: 22px;
          margin: 8px 0;
        }

        .status {
          background-color: color.$gray-800;
          border: 2px solid color.$gray-100;
          border-radius: 5px;
          height: 98px;
          margin-bottom: 8px;
          margin-left: 15px;
          padding: 0 15px;
          width: calc(100% - 30px);

          &.running {
            background-color: color.$success;
          }

          &.faulted {
            background-color: color.$danger;
          }

          &.default {
            background-color: color.$warning;
          }
        }

        .metric {
          color: color.$gray-300;
          display: flex;
          justify-content: space-between;
          padding: 0 15px;
        }
      }
    }

    .oee-metrics {
      display: flex;
      height: 375px;
      justify-content: space-between;
      margin-bottom: 22px;
      margin-top: 50px;
      width: 100%;

      .metric-col:first-of-type {
        margin-left: 40px;
      }

      .metric-col:nth-of-type(2) {
        margin-left: 60px;
        margin-right: 60px;
      }

      .metric-col:last-of-type {
        margin-right: 40px;
      }

      .metric-col {
        height: 375px;
        text-align: center;
        width: 33.33%;

        .oee-container {
          background-color: color.$gray-900;
          border: 1px solid color.$gray-100;
          color: color.$gray-300;
          font-size: 85px;
          font-weight: 300;
          height: 134px;
          letter-spacing: -1.09px;
          line-height: 130px;
          text-align: center;
          width: 100%;
        }

        .shift-box {
          margin-top: 98px;
        }

        .rejects-box .oee-container,
        .good-box .oee-container {
          font-size: 7.0625rem;
          letter-spacing: 4.01px;
          line-height: 127px;
        }

        .good-box {
          margin-bottom: 12px;

          .oee-container {
            color: color.$success;
          }
        }

        .rejects-box {
          .oee-container {
            color: color.$danger;
          }
        }

        .oee-box {
          .oee-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            height: 328px;
            padding: 0;

            .data {
              height: 163px;
              text-align: center;
              width: 50%;

              .tag {
                font-size: 1.1rem;
                height: 32px;
                letter-spacing: 0.01px;
                line-height: 32px;
                width: 100%;
              }

              .value {
                font-size: 6vw;
                height: 131px;
                letter-spacing: -2.59px;
                line-height: 120px;
                width: 98%;
              }
            }

            .oee,
            .availability {
              border-bottom: 1px solid color.$gray-100;
            }

            .availability,
            .quality {
              border-left: 1px solid color.$gray-100;
            }

            .oee {
              .value {
                color: color.$gray-0;
              }
            }
          }
        }
      }
    }

    .label {
      color: color.$gray-100;
      font-size: 2.25rem;
      height: 42px;
      letter-spacing: -1.09px;
      line-height: 42px;
      margin-bottom: 6px;
    }

    .custom-button-label {
      color: color.$gray-100;
      font-size: 2.25rem;
      height: 42px;
      letter-spacing: -1.09px;
      line-height: 42px;
      margin-bottom: 6px;
      margin-top: 50px;
    }

    .custom-oee-buttons {
      background-color: color.$gray-900;
      border: 1px solid color.$gray-100;
      display: flex;
      height: 174px;
      margin-bottom: 10px;

      .button {
        cursor: pointer;
        height: 174px;
        margin: 0;
        padding: 0;
        text-align: center;

        .label {
          color: color.$gray-100;
          font-size: 1.125rem;
          height: 22px;
          letter-spacing: 0.01px;
          line-height: 22px;
          margin: 8px 0;
          text-transform: uppercase;
        }

        .button-status {
          background-color: color.$gray-800;
          border: 2px solid color.$gray-100;
          border-radius: 5px;
          cursor: pointer;
          height: 98px;
          margin-bottom: 8px;
          margin-left: 15px;
          padding: 0 15px;
          width: calc(100% - 30px);

          &.disabled {
            cursor: not-allowed;
          }
        }

        .metric {
          color: color.$gray-300;
          display: flex;
          justify-content: space-between;
          padding: 0 15px;
        }
      }

      .add-button::before {
        display: table;
        font-size: 5.625rem;
        margin-top: 42px;
        text-align: center;
      }

      .add-button:hover {
        color: color.$primary;
      }
    }
  }
}

.oee-config-panel {
  .custom-buttons {
    border-top: 2px solid #bbb;
    color: #8f8f8f;
    font-size: 0.75rem;
    margin-left: 10px;
    padding-bottom: 5px;
    padding-top: 15px;
    width: 100%;
  }

  .station-options {
    margin-top: -10px;
    padding-bottom: 10px;
  }

  .button-group {
    border-top: 2px solid #bbb;
    margin-right: 10px;
    padding-top: 15px;
  }

  .button-select {
    padding-right: 0;
  }

  .button-options {
    margin-top: -10px;
    padding-right: 0;
  }

  .color-picker {
    margin: 0;
    padding: 0;
    text-align: center;

    .active-label {
      color: #878787;
      font-size: 0.875rem;
      margin-top: -8px;
      padding-bottom: 7px;
      white-space: nowrap;
    }
  }

  .event-actions {
    border-top: 1px solid #bbb;
    color: #8f8f8f;
    font-size: 0.75rem;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    padding-bottom: 5px;
    padding-top: 15px;
    width: 100%;
  }
}

.side-panel.oee-station-config-panel {
  height: calc(100% - 134px);
  top: 50px;
}
