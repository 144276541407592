.standard-modal {
  .modal-content .gray-0 {
    .ember-basic-dropdown {
      width: 100%;
    }
  }
}

.plant-editor-area {
  width: 470px;

  input.ember-text-field {
    width: 60px;
  }
}
