@use '_color';
@use 'bootstrap';
@use 'sass:map';

.pnotify-container {
  border: 7px solid rgba(0, 0, 0, 20%);
  border-radius: 3px;

  &.bootstrap4-error {
    background: color.$danger;
    color: #fff;
  }

  &.bootstrap4-info {
    background: color.$info;
    color: #fff;
  }

  &.bootstrap4-success {
    background: color.$success;
    color: #fff;
  }

  &.bootstrap4-warning {
    background: color.$warning;
    color: #fff;
  }
}

.pnotify-shadow {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 80%) !important;
}

.pnotify-icon,
.pnotify-text {
  font-size: map.get(bootstrap.$font-sizes, 1);
}
