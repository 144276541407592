@use '_color' as color2;
@use '_variables';
@use 'bootstrap';
@use 'sass:map';

.bi-tabulator {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tabulator-header {
    .tabulator-col {
      background-color: #6b7385;
      color: #fff;
      font-size: map.get(bootstrap.$font-sizes, 3);
      font-weight: normal;
      text-transform: uppercase;
    }

    div:last-of-type {
      border-right: 0;
    }
  }

  .tabulator-row {
    &:hover {
      background-color: color2.$gray-700;
    }

    .tabulator-cell {
      border-right: none;
    }
  }

  .tabulator-tree-level-0:not(.has-children) {
    .tabulator-cell:first-child {
      padding-left: 4px;
    }

    .tabulator-cell {
      padding-left: 4px;
    }
  }

  .tabulator-footer {
    background-color: #6b7385;
    border-bottom: bootstrap.$dark 1px solid;
    border-left: bootstrap.$dark 1px solid;
    border-right: bootstrap.$dark 1px solid;
  }

}
