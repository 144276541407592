.component-trigger-configs {
  .hardware-col {
    width: 20%;
  }

  .event-col {
    width: 5.5rem;
  }

  .absolute-trash {
    position: absolute;
    right: -1.6rem;
  }
}
