@use 'sass:color';
@use '_color' as color2;

.login-page {
  height: 100%;

  .login-container {
    background-color: color2.$gray-0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 30%);
    margin-top: 60px;
    padding: 40px 0 20px;
  }

  .form-signin {
    margin: 0 auto;
    max-width: 330px;
    padding: 15px;

    .form-control {
      box-sizing: border-box;
      height: auto;
      padding: 10px;
      position: relative;
    }
  }

  .login-field {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .invalid-login {
    color: red;
    margin-top: 10px;
  }

  .signin-prompt {
    padding-top: 10px;
  }
}
