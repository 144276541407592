@use 'sass:color';
@use 'sass:map';
@use '_color' as styles-color;
@use 'bootstrap';

$ember-grid-border: 1px solid styles-color.$gray-200;

.component-ember-grid {
  height: 100%;
  position: relative;

  .ember-grid {
    border: none;
    margin-bottom: 0;
  }

  .ember-grid-component {
    height: 100%;

    .ember-grid {
      border-collapse: separate;
      border-spacing: 0;
      text-align: left;

      th:first-child,
      td:first-child {
        border-left: $ember-grid-border;
      }

      td {
        border-bottom: $ember-grid-border;
        border-right: $ember-grid-border;
        border-top: none;
        vertical-align: middle;

        label {
          margin-bottom: 0;
        }
      }

      th {
        background: styles-color.$gray-100;
        border-bottom: $ember-grid-border;
        border-right: $ember-grid-border;
        border-top: $ember-grid-border;
        position: sticky;
        top: 0;
      }
    }
  }

  .ember-grid-action {
    color: #333;
    cursor: pointer;

    &:hover {
      color: #333;
    }
  }

  .ember-grid-component .ember-grid th,
  .ember-grid-header-cell {
    border-color: $ember-grid-border;
    color: styles-color.$gray-700;
    cursor: pointer;
    font-size: 8pt;
    font-weight: 600;
    letter-spacing: 0.5px;
    padding: 9px;
    text-align: left;
    text-shadow: 0 1px 0 #fff;
    text-transform: uppercase;
    vertical-align: middle;
    z-index: map.get(bootstrap.$zindex-levels, 'table-header');
  }

  .ember-grid-header-cell {
    cursor: default;
    text-align: left;
  }

  .grid-title {
    font-size: 12pt;
    font-weight: bold;
  }

  &.pane-grid {
    table {
      border: 1px solid color.adjust(styles-color.$gray-0, $lightness: -10%);

      thead {
        th {
          border: 1px;
          font-size: 10pt;
          text-align: left;
        }
      }

      tbody {
        td {
          border: none !important;
          font-size: 11pt;
          text-align: left;
        }

        tr:last-child {
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }

  .load-wrapper {
    display: inline-flex;
    padding: 5px;
    width: 100%;

    .app-load-more {
      background-color: styles-color.$warning;
      color: #fff;
      margin: 0 auto;
      text-transform: uppercase;
    }
  }
}
