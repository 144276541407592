@use 'sass:color';
@use '_color' as color2;
@use 'bootstrap';
@use 'sass:map';

.main-overflow {
  overflow-x: hidden;
  overflow-y: auto;
}

.sticky-bottom {
  bottom: 0;
  position: sticky;
  z-index: bootstrap.$zindex-sticky;
}

.font-size-base {
  font-size: bootstrap.$font-size-base;
}

.inline {
  label {
    display: inline;
    margin-left: 15px;
    margin-right: 5px;
  }

  li {
    display: inline;
    margin: 0 10px 0 0;
  }
}

.selected {
  --bs-table-bg: color2.$gray-100;

  background-color: color2.$gray-100;

  &:hover {
    background-color: color.adjust(color2.$gray-100, $lightness: -5%);
  }
}

.good {
  background-color: color2.$success;

  &:hover {
    background-color: color.adjust(color2.$success, $lightness: -5%);
  }
}

.invalid {
  background-color: color2.$warning;

  &:hover {
    background-color: color.adjust(color2.$warning, $lightness: -5%);
  }
}

.bad {
  background-color: color2.$danger;
  color: white;

  &:hover {
    background-color: color.adjust(color2.$danger, $lightness: -5%);
  }
}

.expired {
  background-color: color2.$gray-200;
  color: white;

  &:hover {
    background-color: color.adjust(color2.$gray-200, $lightness: -5%);
  }
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.icon-link {
  cursor: pointer;
}

.light-text {
  color: color2.$light-text;
}

.condensed {
  font-stretch: condensed;
}

.square-color-picker {
  background-color: transparent;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  height: 32px;
  max-width: 32px;
  min-width: 32px;
  padding: 0;
  position: relative;
  width: 28px;

  &:focus {
    outline: none;
  }
}

.preserve-spaces {
  white-space: pre-wrap;
}

.allow-pointer-events {
  pointer-events: all;
}

.display-contents {
  display: contents;
}

.opacity-0 {
  opacity: 0;
}

.btn.btn-peripheral-dark {
  --bs-btn-padding-x: 0;
  --bs-btn-padding-y: 0;
  --bs-btn-color: #{color2.$gray-400};
  --bs-btn-hover-color: #{color2.$gray-100};
  --bs-btn-active-color: #{color2.$gray-0};
  --bs-btn-active-border-color: transparent;
}

.btn.btn-peripheral {
  --bs-btn-padding-x: 0;
  --bs-btn-padding-y: 0;
  --bs-btn-color: #{color2.$gray-500};
  --bs-btn-hover-color: #{color2.$gray-800};
  --bs-btn-active-color: #{color2.$gray-900};
  --bs-btn-active-border-color: transparent;
}


@for $i from 1 through 10 {
  .width-rem-#{$i} {
    width: #{$i}rem;
  }

  .height-rem-#{$i} {
    height: #{$i}rem;
  }
}

.background-gray-100 {
  background-color: color2.$gray-100;
}
