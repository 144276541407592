@use 'bootstrap';

.modal.complex-option-modal .modal-dialog {
  font-weight: bootstrap.$font-weight-light;

  .gray-0 {
    background: #f1eff0;

    .option-name {
      margin-left: -10px;
      padding-bottom: 60px;

      .builder-complex-option-name {
        width: 100%;
      }
    }
  }

  .modal-header,
  .modal-footer {
    background: #68635f;
    color: #dddbdb;
    font-size: 12pt;
  }

  .modal-footer-field {
    border-left: 2px solid #f1eff0;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
  }
}
