@use 'bootstrap';
@use 'sass:map';

.tooltip-center {
  top: 33.3% !important;
}

.tooltip-inner {
  background-color: rgba(68, 68, 68, 100%);
  border: 1px solid #5f6061;
  border-radius: 4px;
  color: #d5d5d5;
  font-size: map.get(bootstrap.$font-sizes, 5);
  max-width: 260px;
  opacity: 0.85;
  padding: 20px;
  text-align: left;
}

.tooltip.arrow::before {
  border-color: transparent;
  border-style: solid;
  content: '';
  position: absolute;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto.arrow::before {
  border-right-color: rgba(68, 68, 68, 100%);
  border-width: 0.4rem 0.4rem 0.4rem 0;
  right: 0;
}
