.eflex-logs {
  .log-message-col {
    max-width: 500px;
    text-align: left;
    word-wrap: break-word;
  }

  .fa-flag {
    color: #a15258;
  }

  .fa-triangle-exclamation {
    color: #a15258;
  }

  .fa-exclamation {
    color: #fcb042;
  }

  .fa-info {
    color: #0597ee;
  }

  .fa-circle-exclamation {
    color: #a15258;
  }
}
