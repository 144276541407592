.component-work-instruction-editor-approvers {
  overflow: hidden;

  .approver-status {
    color: #5c5c5c;
    display: inline-flex;
    line-height: 1.5;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .approver-status-icon {
      border: 1px solid rgba(70, 70, 68, 50%);
      border-radius: 50%;
      display: inline-block;
      height: 10px;
      margin-right: 5px;
      margin-top: 5px;
      width: 10px;

      &.approved {
        background-color: #10c410;
      }

      &.rejected {
        background-color: #eb1726;
      }

      &.pending {
        background-color: #f6d84d;
      }
    }
  }
}
