.component-inputs-opacity {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;

  label {
    left: 0;
    margin-left: 2rem;
    position: absolute;

    &.hundreds {
      margin-left: 2.5rem;
    }

    &.ones {
      margin-left: 1.5rem;
    }

    &.disabled {
      opacity: 0.5;
    }
  }
}
