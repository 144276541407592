.component-work-instruction-editor-properties-drawer {
  background-color: #f0f0f0;
  display: flex;
  height: 100%;
  max-width: 270px;
  width: auto;

  &.collapsed {
    display: none;
  }
}
