/* stylelint-disable selector-class-pattern */
.eflex-styleguide {
  background-color: white;

  .FreestyleGuide-header {
    background-color: white;
    height: 86px;
    position: fixed;
    width: 100%;
    z-index: 1000;
  }

  .FreestyleGuide-subtitle {
    color: #999;
  }

  .FreestyleGuide-aside {
    .FreestyleUsageControls {
      font-size: 12px;
    }
  }

  .FreestyleUsageControls-button {
    box-shadow: none;
    font-size: 10px;
  }

  .FreestyleUsageControls-itemLabel {
    font-size: inherit;
  }

  .FreestyleSection-name {
    border-bottom: solid 2px #ccc;
    font-size: 24px;
    max-width: 100%;
  }

  .FreestyleSubsection {
    max-width: 100%;
  }

  .FreestyleSubsection-name {
    border-bottom: solid 1px #ddd;
    color: #777;
    font-size: 22px;
    font-weight: lighter;
    max-width: 100%;
  }

  .FreestyleUsage-usage {
    padding-bottom: 0;
  }

  .FreestylePalette-description {
    font-size: inherit;
  }

  .FreestyleVariant--inline {
    display: inline-grid;
  }

  .FreestyleGuide-body {
    margin-top: 86px;
  }

  .FreestyleGuide-content {
    padding: 10px;
  }

  .FreestyleAnnotation {
    font-size: 12px;
    margin-bottom: 6px;
  }
}
/* stylelint-enable selector-class-pattern */
