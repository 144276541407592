.component-jem-bolt {
  .icon {
    align-self: center;
    font-size: 1.5rem;
    max-width: 20px;
    padding-bottom: 0.375rem;
    width: 24px;
  }

  .icon-x {
    padding-left: 3px;
  }

  .tightening-col {
    align-self: center;
  }

  .status-display {
    max-width: 400px;
  }

  &.is-stale {
    .detail-text-value,
    .bolt-error {
      opacity: 0.6;
    }
  }

  &.active {
    .bolt-name {
      font-weight: bold;
    }
  }
}
