@use 'sass:color';
@use 'sass:map';
@use '_variables';
@use 'bootstrap';

$popover-colors: (
  success: (
    border-color: #76c654,
    main-background: #b6ff9c,
    message-text: #2a2a2a
  ),
  danger: (
    border-color: #c60601,
    main-background: #d4807e,
    message-text: #fff
  ),
  warn: (
    border-color: #f7da53,
    main-background: #fbeeaf,
    message-text: #2a2a2a
  ),
  info: (
    border-color: #bee5eb,
    main-background: #ebf3f5,
    message-text: #0c5460
  )
);

@function popover-color($level, $color-def) {
  $color: map.get(map.get($popover-colors, $level), $color-def);

  @return $color;
}

.standard-popover.modal {
  &.show {
    .modal-dialog {
      transform: translate(0, calc(50vh - 100px));
    }
  }

  .modal-dialog {
    max-width: 360px;
    transform: translate(0, calc(50vh - 125px));
  }

  .modal-content {
    border: 3px solid;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 50%);
    padding: 0 0 20px;
  }

  @each $level in variables.$levels {
    &.#{$level} .modal-content {
      background-color: popover-color($level, main-background);
      border-color: popover-color($level, border-color);
      color: popover-color($level, message-text);
    }
  }

  .btn-close {
    margin-right: 0;
    opacity: 1;
    padding: 0.3rem 0.7rem 0;
    z-index: 1;
  }

  @each $level in variables.$levels {
    &.#{$level} .btn-close {
      color: popover-color($level, message-text);

      &:hover {
        color: color.invert(popover-color($level, message-text));
      }
    }
  }

  .modal-header {
    background: inherit;
    border-bottom: none;
    color: inherit;
    font-size: map.get(bootstrap.$font-sizes, 2);
    font-weight: bootstrap.$font-weight-bold;
    font-weight: 500;
    line-height: 1.2;
    padding: 20px 0 0 calc(15% + 20px);
  }

  .gray-0 {
    background: transparent;
    box-shadow: none;
    font-size: map.get(bootstrap.$font-sizes, 2);
    padding: 0 0 0 15%;
  }

  .side-panel {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 15%;
  }

  @each $level in variables.$levels {
    &.#{$level} .side-panel {
      background-color: popover-color($level, border-color);
    }
  }

  .eflex-icon {
    margin: 20px auto 0;
  }

  .main-panel {
    background: none;

    header,
    .body {
      bottom: 3px;
      position: relative;
    }

    header {
      display: flex;
      font-weight: bootstrap.$font-weight-bold;

      .text {
        font-size: map.get(bootstrap.$font-sizes, 2);
        margin: 0;
        margin-top: 2px;
      }
    }

    .body {
      font-size: map.get(bootstrap.$font-sizes, 2);
      margin-top: 5px;

      .process-limits {
        font-size: map.get(bootstrap.$font-sizes, 2);
      }
    }
  }
}
