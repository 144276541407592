@use 'bootstrap';
@use 'sass:color';
@use '_color' as color2;

.component-quantity-select {
  display: block;
  font-weight: bootstrap.$font-weight-light;
  user-select: none;

  .btn-eflex {
    padding: 0;
  }

  .quantity {
    color: color2.$primary;
    display: inline-block;
    font-size: 20px;
    margin-left: 8px;
    margin-top: 2px;
    vertical-align: middle;

    .count {
      cursor: none;
      font-weight: bootstrap.$font-weight-normal;
      pointer-events: none;
    }

    .carrot {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      cursor: pointer;
      height: 0;
      pointer-events: all;
      position: relative;
      width: 0;
    }

    .carrot-up {
      border-bottom: 10px solid color2.$primary;
      bottom: 25px;
      left: 2px;
    }

    .carrot-down {
      border-top: 10px solid color2.$primary;
      right: 10px;
      top: 26px;
    }

    &.quantity-sm {
      font-size: 14px;
      margin-left: 5px;

      .carrot {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }

      .carrot-up {
        border-bottom: 7px solid color2.$primary;
        bottom: 18px;
        left: 2px;
      }

      .carrot-down {
        border-top: 7px solid color2.$primary;
        right: 6px;
        top: 18px;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;

      .carrot {
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}
