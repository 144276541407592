@use 'sass:color';
@use 'sass:map';
@use '_color' as getColor;
@use 'bootstrap';
@use '_variables';

// Temporary
.square-button:disabled {
  cursor: not-allowed;
  pointer-events: all;
}

// workaround https://github.com/emberjs/ember.js/issues/19285
button[disabled] > * { pointer-events: none; }

// Inputs
input,
textarea {
  &:disabled {
    cursor: not-allowed;
    opacity: variables.$disabled-opacity !important;
  }
}

// Buttons
.btn-group {
  > label:focus {
    box-shadow: none !important;
  }

  &.radio-btn-group {
    .btn-check:checked + label {
      background-color: getColor.$primary;
      border-color: getColor.$primary;
      color: getColor.$gray-0;
    }
  }
}

// Forms
.form-control.is-valid:focus {
  &#{&} {
    border-color: getColor.$primary;
    box-shadow: bootstrap.$input-focus-box-shadow;
  }
}

.is-required > label::after {
  color: getColor.$danger;
  content: '*';
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: color.adjust(getColor.$gray-0, $lightness: 4%);
}

.popover {
  max-width: none;
}

*:focus {
  outline-color: getColor.$primary !important;
}

input[readonly] {
  border: none !important;
  box-shadow: none !important;
}

strong {
  color: getColor.$gray-700;
}

fieldset {
  border: 1px solid rgba(119, 113, 105, 40%);
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}

legend {
  background-color: color.adjust(getColor.$gray-700, $lightness: 49%);
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px 0 color.adjust(getColor.$gray-700, $lightness: 50%);
  color: getColor.$gray-700;
  line-height: 20px;
  padding: 3px 10px 1px;
  width: auto;
}

select {
  border: 1px solid color.adjust(bootstrap.$border-color, $lightness: -10%);
  border-radius: 3px;
  color: black;
  padding: 5px 2px 4px;
}

label.radio {
  display: block;
  line-height: 30px;
}

.form-text {
  color: color.adjust(bootstrap.$border-color, $lightness: -25%);
  font-size: map.get(bootstrap.$font-sizes, 3);
  font-style: italic;
}

.form-check-label {
  &::after {
    background: no-repeat 100% / 100% 100%;
  }
}

.form-group {
  margin-bottom: 0.5rem;
  position: relative;

  label {
    color: map.get(bootstrap.$theme-colors, 'dark');
    font-size: bootstrap.$font-size-sm;
  }

  &.small label {
    font-size: bootstrap.$small-font-size;
  }
}

textarea.form-control {
  height: auto;
}
