@use 'sass:color';
@use '_color' as styles-color;

.component-evision-settings-config {
  .grip {
    color: styles-color.$gray-600;
    cursor: pointer;
  }

  .format-card {
    border-color: styles-color.$gray-600;
  }

  .left-col {
    min-width: 64px;
  }

  .k-block {
    background-color: #f5f5f5;
    background-position: 0 center;
    background-repeat: repeat;
    border-color: #e3e3e3;
    border-style: solid;
    border-width: 1px;
    color: #2e2e2e;
    line-height: normal;
    margin-bottom: 20px;
    outline: 0;
    padding: 2px;
    text-align: center;

    .k-header {
      $first: color.adjust(color.adjust(#fcb042, $saturation: 30%), $lightness: -8%);
      $second: color.adjust(color.adjust(#fcb042, $saturation: 10%), $lightness: 6%);

      background: linear-gradient(to bottom, $first 0, $second 100%);
      background-color: #e3e3e3;
      background-image: none, linear-gradient(to bottom, #f6f6f6 0, #eaeaea 100%);
      background-position: 50% 50%;
      background-repeat: repeat;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-color: #c5c5c5;
      box-sizing: content-box;
      color: #2e2e2e;
      font-size: 12px;
      font-weight: bold;
      height: 1.1em;
      letter-spacing: 0.5px;
      line-height: 1.1em;
      margin: 0 0 10px;
      min-height: 16px;
      overflow: visible;
      padding: 6px 0 2px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      white-space: normal;
      white-space: nowrap;
      width: 100%;
    }

    .k-shadow {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 30%);
    }
  }
}
