@use 'sass:color';
@use '_color' as color2;
@use 'bootstrap';
@use 'sass:map';

.jem-task-row {
  cursor: pointer;

  &:hover {
    background-color: color2.$gray-100;
  }

  &.selected {
    border-left: 6px solid color2.$warning;

    .jem-task {
      font-weight: bold;
    }
  }

  .jem-task-details,
  .jem-component-option {
    background-color: color2.$gray-0;

    &:hover {
      background-color: color2.$gray-100;
    }
  }

  &.success {
    background-color: color2.$success;

    &:hover {
      background-color: color.scale(color2.$success, $lightness: -10%);
    }

    .jem-task-details,
    .jem-component-option {
      background-color: color.scale(color2.$success, $lightness: 35%);
    }
  }

  &.danger {
    background-color: color2.$danger;

    &:hover {
      background-color: color.scale(color2.$danger, $lightness: -10%);
    }

    .jem-task-details,
    .jem-component-option {
      background-color: color.scale(color2.$danger, $lightness: 35%);
    }
  }

  &.info {
    background-color: color2.$non-photo-blue;

    &:hover {
      background-color: color.scale(color2.$non-photo-blue, $lightness: -10%);
    }

    .jem-task-details,
    .jem-component-option {
      background-color: color.scale(color2.$non-photo-blue, $lightness: 35%);
    }
  }

  &.warning {
    background-color: color2.$warning;

    &:hover {
      background-color: color.scale(color2.$warning, $lightness: -10%);
    }

    .jem-task-details,
    .jem-component-option {
      background-color: color.scale(color2.$warning, $lightness: 35%);
    }
  }

  &.task-not-displayed {
    display: none;
  }

  &.not-required > td {
    background-color: #ccc;

    &:hover {
      background-color: color.adjust(#ccc, $lightness: -7%);
    }
  }

  &.active-selection {
    background-color: #83c2e6;

    &:hover {
      background-color: color.adjust(#83c2e6, $lightness: -7%);
    }
  }

  > td {
    vertical-align: middle;
  }

  .task-icon {
    background-repeat: no-repeat;
    background-size: 25px 25px;
    flex-shrink: 0;
    height: 25px;
    margin: auto 0;
    width: 25px;
  }

  .task-name {
    line-height: 25px;
    overflow: hidden;
    padding-left: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .jem-status,
  .jem-status-button {
    font-weight: bootstrap.$font-weight-bold;
  }

  .jem-status {
    text-transform: uppercase;
  }

  .btn-task {
    @include bootstrap.button-variant(rgba(color2.$light, 0.75), color2.$gray-600);
  }

  td.jem-status {
    button {
      min-width: 100px;
      width: fit-content;
    }

    .ok-button,
    .check-button,
    .restart-button {
      color: color2.$success !important;
    }

    .reject-button,
    .recheck-button,
    .retry-button {
      color: color2.$danger !important;
    }
  }

  td.jem-description {
    &.overflow-visible {
      overflow: visible;
    }

    .jem-process-data {
      align-items: center;
      display: flex;
      font-size: map.get(bootstrap.$font-sizes, 4);
      font-weight: 100;
      margin-bottom: 5px;
      margin-top: 5px;

      input[type='text'] {
        width: 100%;
      }

      .process-data-bit input[type='radio'] {
        margin: 4px 5px 0;
      }

      .process-data-def-name {
        font-style: italic;
        margin-right: 10px;
      }

      .process-data-def-value {
        width: 100%;
      }
    }
  }

  .complex-component-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: sub;
  }
}
