@use '_color' as styles-color;
@use 'bootstrap';
@use '_mixins';
@use 'sass:color';

$jem-timer-section-width: 220px;

.process-data-invalid-popover {
  .popover-body {
    background-color: #e65656;
    border-radius: 3px;
    color: white;
    font-size: 18px;
    font-style: italic;
    height: 45px;
    padding: 8px;
    white-space: nowrap;
  }
}

.jem-page {
  grid-area: no-chrome-workspace;

  .jem-no-stations {
    color: styles-color.$non-photo-blue;
    font-size: 2rem;
    padding-left: 20px;
    text-align: center;

    .jem-no-stations-return {
      font-size: 1.5rem;
    }
  }

  .task-info-section {
    display: flex;
  }

  .jem-content-area {
    display: flex;
    flex-direction: column;
    min-height: 0;
    position: relative;
    width: 100%;

    .task-details {
      &:not(:empty) {
        padding: 0.5rem;
      }

      >* {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        &:last-child:empty {
          margin-bottom: -1rem;
        }
      }

      .jem-bolts {
        >* {
          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          &:last-child:empty {
            margin-bottom: -1rem;
          }
        }
      }
    }

    .adjust-line-height {
      line-height: 1.2;
    }

    .detail-text-label,
    .detail-text-small,
    .detail-text-value {
      color: #5c5954;
    }

    .detail-text-label {
      font-size: 1rem;
    }

    .detail-text-small {
      font-size: 0.75rem;
      text-transform: uppercase;
    }

    .detail-text-value {
      color: styles-color.$default-text;
      font-size: 1.5rem;
    }

    &.task-list-view {
      .task-details {
        margin-right: 1rem;
      }
    }

    &.single-task-view {
      $task-info-width: 300px;

      .main-info-section {
        .work-instruction {
          height: 100%;
          width: calc(100% - #{$task-info-width});
        }

        .jem-image-container {
          height: 100%;

          img {
            padding: 0;
          }
        }
      }

      .task-info {
        color: #fff;
        height: 100%;
        position: relative;
        width: $task-info-width;
      }

      .detail-text-label {
        color: #fff;
        font-size: 0.875rem;
      }

      .detail-text-small {
        color: styles-color.$gray-600;
        font-size: 0.75rem;
        text-transform: uppercase;
      }

      .detail-text-value {
        color: #fff;
        font-size: 2rem;
        font-weight: normal;

        &.btn {
          color: styles-color.$default-text;
        }
      }

      .timer-section {
        margin-top: 0;
      }
    }
  }

  .gutter-horizontal {
    background: url('/images/icons/scrub-bar-vertical.svg') no-repeat center;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    min-width: 2rem;
    opacity: 0.5;

    &:hover {
      opacity: 1;

      #jem-task-display {
        border-left-color: #43464f;
      }
    }
  }

  .jem-action-controller {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-width: 14rem;

    button {
      color: #fff;
      font-size: 1.5rem;
      font-weight: bold;
      height: 100%;
      max-height: 5rem;
      min-height: 2rem;
      padding: 0;
      text-transform: uppercase;
      width: 100%;

      &.hold,
      &.ok-button {
        color: styles-color.$gray-900;
      }
    }

    .btn-hold {
      @include bootstrap.button-variant(#f2bd00, color.adjust(#f2bd00, $lightness: -10%));

      color: #fff;
    }

    .btn-stop {
      @include bootstrap.button-variant(#ff6a00, color.adjust(#ff6a00, $lightness: -10%));
    }

    .jem-ok-button-portal {
      max-height: 10rem;
      min-height: 6rem;

      button {
        max-height: inherit;
        min-height: inherit;
      }

      .extra-ok-button {
        display: none;

        &:only-child {
          display: block;
        }
      }
    }
  }
}

#jem-task-grid {
  max-width: calc(98% - 20px);

  @media screen and (max-width: 1200px) {
    max-width: calc(98% - 25px);
  }

  @media screen and (max-width: 900px) {
    max-width: calc(98% - 30px);
  }

  @media screen and (max-width: 650px) {
    max-width: calc(90% - 10px);
  }
}

#jem-task-display {
  opacity: 1;
  pointer-events: none;
}

#jem-task-grid-table {
  overflow: hidden;
}

.jem-view-toggles {
  button.list-view,
  button.single-view {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 6.5px;
    opacity: 0.5;
    outline: none;
    padding: 0;

    &.active {
      border: 2px solid styles-color.$primary;
      border-radius: 4px;
      opacity: 1;
    }

    >i {
      $list-view-toggle-size: 38px;

      display: block;
      height: 38px;
      width: $list-view-toggle-size;
    }
  }
}

.app-jem-oee-button {
  position: relative;

  .status {
    bottom: 0;
    font-size: 12px;
    font-weight: bold;
    left: 20%;
    line-height: 1;
    position: absolute;
    right: 20%;
    text-align: center;

    &.running {
      color: styles-color.$success;
    }

    &.faulted {
      color: styles-color.$danger;
    }

    &.starved,
    &.blocked,
    &.changeover,
    &.breaks {
      color: styles-color.$warning;
    }
  }
}
