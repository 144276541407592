@use 'bootstrap';

#carousel-modal {
  display: flex;
  height: 100%;
  position: relative;

  .first-column {
    align-self: flex-start;
    height: 100%;
    width: 25%;

    nav {
      color: #808080;
      display: flex;
      font-size: 22px;
      font-weight: bootstrap.$font-weight-bold;

      .left-arrow,
      .right-arrow {
        cursor: pointer;
        height: 32px;
        width: 32px;
      }

      .left-arrow {
        background: url('/images/carouselarrow-previous.svg') no-repeat;
        margin-right: 12px;
      }

      .right-arrow {
        background: url('/images/carouselarrow-next.svg') no-repeat;
      }
    }

    .status {
      font-size: 28px;
      font-weight: bootstrap.$font-weight-bold;
      margin: 30px 0;

      &.pass {
        color: #2db002;
      }

      &.fail {
        color: #d70000;
      }
    }

    .info {
      font-size: 16px;
    }

    .download-button {
      background: url('/images/download.svg') no-repeat;
      bottom: 0;
      height: 50px;
      left: 0;
      position: absolute;
      width: 50px;
    }
  }

  .second-column {
    height: 100%;
    width: 75%;

    .img-contain {
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      width: 100%;

      .carousel-image {
        text-align: center;

        img {
          display: block;
          height: auto;
          object-fit: contain;
          width: 100%;
        }

        .pdf-icon {
          height: 200px;
        }
      }

      .img-caption {
        text-align: center;
      }
    }
  }
}
