@mixin responsive-text($original-value) {
  font-size: $original-value;

  @media (max-width: 800px) {
    font-size: $original-value * 0.6;
  }

  @media (min-width: 801px) and (max-width: 1600px) {
    font-size: $original-value * 0.7;
  }

  @media (min-width: 1601px) and (max-width: 1800px) {
    font-size: $original-value * 0.8;
  }
}
