@use '_color';

.component-jem-task-specific-display-multispindle-details {
  .bootstrap-table {
    background-color: color.$secondary;
  }

  .bootstrap-table .form-row.spindle-row {
    background-color: color.$gray-400;

    &.spindle-started {
      background-color: color.$non-photo-blue;
    }

    &.spindle-good {
      background-color: color.$success;
    }

    &.spindle-rejected {
      background-color: color.$danger;
    }
  }
}
