.component-jem-task-display-button-details {
  .jem-process-data {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: inherit;
    }

    .btn-group {
      button {
        min-width: 100px;
      }
    }
  }
}
