@use '_color';

.component-button-power-select {
  .ember-power-select-trigger {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: inherit;
    cursor: pointer;
    outline: none;
    width: fit-content;
  }

  .ember-power-select-status-icon {
    visibility: hidden;
  }

  .ember-power-select-placeholder-icon {
    font-size: 1.25rem;
    margin-right: 0.75rem;
    position: relative;
  }

  .ember-power-select-placeholder {
    align-items: center;
    color: inherit;
    display: flex;
    font-size: 1rem;
    font-style: normal;
    font-weight: bold;
    height: 100%;
    margin-left: 0;
    padding: 0 0.75rem;
    width: 100%;
  }

  .ember-power-select-options {
    width: fit-content;
  }
}
