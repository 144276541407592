@use 'bootstrap';

.component-and-or {
  img.and-or {
    margin-top: 10px;
    opacity: 0.5;
    width: 50px;
  }

  .and-or-wrapper {
    color: #777;
    font-weight: bootstrap.$font-weight-light;
    width: calc(100% - 58px);

    .or-container {
      width: 100%;

      .or-group {
        left: 8px;
        position: relative;
        width: 100%;

        &.or1-select {
          margin-bottom: 13px;
        }
      }
    }
  }
}
