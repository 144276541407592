@use 'sass:map';
@use '_variables';
@use '_functions';

@mixin icon($icon-url, $width: 30px, $height: $width) {
  background-image: functions.image-url($icon-url);
  background-repeat: no-repeat;
  height: $height;
  margin: auto;
  width: $width;
}

@mixin task-icon($taskType) {
  @include icon('#{$taskType}-icon.svg');

  &.light {
    @include icon('#{$taskType}-icon-light.svg');
  }
}

.icon {
  display: inline-block;
}

.tree-item-label:hover i {
  display: inline;
}

.trash-svg {
  cursor: pointer;
  height: 16px;
  width: 13px;
}

.errorproofing-icon {
  @include task-icon('errorproofing');
}

.cobot-icon {
  @include task-icon('cobot');
}

.imagecapture-icon {
  @include task-icon('efleximagecapture');
}

.vision-icon {
  @include task-icon('visionsystem');
}

.timer-icon {
  @include task-icon('timer');
}

.button-icon {
  @include task-icon('button-task');
}

.barcode-icon {
  @include task-icon('barcodereader');
}

.serialnumbertransfer-icon {
  @include task-icon('jemserialnumbertransfer');
}

.plc-icon {
  @include task-icon('plc');
}

.torque-icon {
  @include task-icon('eflextorque');
}

.pick-icon {
  @include task-icon('eflex-pick');
}

.lightguide-icon {
  @include task-icon('lightguide');
}

.nodered-icon {
  @include task-icon('nodered');
}

.print-icon {
  @include task-icon('printtask');
}

.decision-icon {
  @include icon('decision-icon.svg');
}

.multispindle-icon {
  @include icon('multispindle-icon.svg', 18px);
}

.pushtoschedule-icon {
  @include icon('pushtoschedule-icon.svg', 18px);
}

.edhr-icon {
  @include icon('unload-icon.svg', 18px);
}

.load-icon {
  @include icon('load-icon.svg', 18px);
}

.custom-tab-icon {
  @include icon('custom-tab-icon.svg', 18px);
}

.wip-icon {
  @include icon('wip-icon.svg', 18px, 16px);
}

.torque-data-icon {
  @include icon('eflextorque-icon.svg', 11px, 16px);
}

.schedule-icon {
  @include icon('schedule-icon.svg', 13px);
}

.eflex-vision-icon {
  @include icon('eflex-vision-icon.svg', 13px);
}

.hardware-icon {
  @include icon('hardware-icon.svg', 13px);
}

.business-intelligence-icon {
  @include icon('BusinessIntelligence.svg', 13px);
}

.code-bracket {
  @include icon('code-bracket.svg', 13px);
}

.oee-nav-icon {
  @include icon('oee.svg', 13px);
}

.dropdown-close-icon {
  @include icon('dropdown-close.svg', 9px);
}

.dropdown-open-icon {
  @include icon('dropdown-open.svg', 9px);
}

.icon-area {
  @include icon('tree-area-icon.svg', 15px);
}

.icon-group {
  @include icon('tree-group-icon.svg', 15px);
}

.tree-genericstation-icon {
  @include icon('tree-genericstation-icon.svg', 15px);

  &.light {
    @include icon('tree-genericstation-light-icon.svg', 15px);
  }
}

.tree-automaticstation-icon {
  @include icon('tree-automaticstation-icon.svg', 15px);
}

.tree-all-icon {
  @include icon('tree-all-icon.svg', 15px);
}

.complex-component-icon {
  $width: 20px;
  @include icon('complex-component.svg', 22px);
}

.grid-icon-green-checkmark {
  @include icon('checkmark-green-grid-icon.svg', 20px, 20px);
}

.grid-icon-merged {
  @include icon('merged-grid-icon.svg', 20px, 15px);
}

.grid-icon-danger-alert-red {
  @include icon('red-danger-alert-grid-icon.svg', 20px, 20px);
}

.grid-icon-warning-alert {
  @include icon('warning-alert-grid-icon.svg', 20px, 20px);
}

.tree-station {
  background-image: functions.image-url('accordion-arrow.svg');
  background-size: 13px 13px;
  height: 13px;
  width: 13px;
}

.tree-station-opened {
  background-image: functions.image-url('accordion-arrow-open.svg');
  background-size: 13px 13px;
  height: 13px;
  width: 13px;
}

.tree-area {
  background-image: functions.image-url('tree-area.svg');
  background-size: 13px 13px;
  height: 13px;
  width: 13px;
}

.group-icon-sm {
  background-image: functions.image-url('tree-group-light-icon.svg');
  background-size: contain;
  height: 12px;
  width: 12px;
}

.tree-group {
  background-image: functions.image-url('tree-group.svg');
  background-size: 13px 13px;
  height: 13px;
  width: 13px;
}

.tree-toggle-down {
  transform: rotate(90deg);
}

.nav-icon {
  @media (max-width: 1600px) {
    font-size: 35px;
    height: 35px;
    width: 35px;
  }

  @media (min-width: 1601px) and (max-width: 1800px) {
    font-size: 50px;
    height: 50px;
    width: 50px;
  }

  cursor: pointer;
  margin-left: 20px;
}

.icon-trash {
  cursor: pointer;
}

.panel-arrow-right,
.panel-arrow-left {
  background-size: 10px 20px;
  height: 20px;
  width: 10px;
}

.panel-arrow-right {
  background-image: functions.image-url('panel/rightpanelarrow.svg');
}

.panel-arrow-left {
  background-image: functions.image-url('panel/leftpanelarrow.svg');
}

i.icon-wie-add-folder {
  @include icon('imageEditor/addfolder-icon.svg', 24px);
}

i.icon-wie-library {
  @include icon('imageEditor/library-icon.svg', 20px);
}

i.icon-wie-approve-reject {
  @include icon('imageEditor/approve-reject-silhouette-icon.svg', 20px);
}

i.icon-wie-approve-reject-pending {
  @include icon('imageEditor/approve-reject-pending-icon.svg', 34px);

  display: inline-block;
}

i.icon-wie-approved {
  @include icon('imageEditor/approved-icon.svg', 20px);

  display: inline-block;
}

i.icon-wie-approved-note {
  @include icon('imageEditor/approved-note-icon.svg', 28px);

  display: inline-block;
}

i.icon-wie-rejected {
  @include icon('imageEditor/rejected-icon.svg', 20px);

  display: inline-block;
}

i.icon-wie-rejected-note {
  @include icon('imageEditor/rejected-note-icon.svg', 28px);

  display: inline-block;
}

.icon-wie-folder {
  @include icon('imageEditor/folder-icon.svg', 100%);

  background-position: center;
  min-height: 80px;
}

.icon-wie-folder-hovered {
  @include icon('imageEditor/folder-icon-hovered.svg', 100%);

  background-position: center;
  min-height: 80px;
}

.icon-wie-library-return {
  @include icon('imageEditor/library-icon.svg', 100%);

  background-position: center;
  min-height: 80px;
}

.icon-wie-library-return-hovered {
  @include icon('imageEditor/library-icon-hovered.svg', 100%);

  background-position: center;
  min-height: 80px;
}

i.list {
  @include icon('jem-toggle-tasklistview.svg', 18px);
}

i.single-item {
  @include icon('jem-toggle-singletaskview.svg', 18px);
}

i.no-rerun-icon {
  @include icon('no-rerun.svg', 40px);
}

$icon-svgs: (
  success: 'checkmark-white-icon.svg',
  danger: 'jem/config-alert-inverted.svg',
  warn: 'warning-alert-icon.svg',
  info: 'information-alert-icon.svg'
);

i.eflex-icon {
  display: inline-block;

  &.info-dark {
    @include icon('info-dark.svg');
  }

  @each $level in variables.$levels {
    &.#{$level} {
      @include icon(map.get($icon-svgs, $level), 48px);
    }
  }
}
