@use '_color' as color2;
@use '_variables';
@use 'bootstrap';

.component-bi-oee-states-table {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tabulator-table {
    position: relative;
    width: 100%;
  }

  .tabulator {
    background-color: color2.$gray-900;

    .tabulator-header .tabulator-col.tabulator-sortable[aria-sort='none'] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
      border-bottom: 6px solid bootstrap.$dark;
      border-top: none;
    }
  }

  .tabulator-tableholder {
    background-color: color2.$gray-900;
    overflow-x: hidden;

    .tabulator-table {
      background-color: color2.$gray-900;
      font-size: 0.875rem;
      width: 100%;
    }
  }

  .tabulator-header {
    .tabulator-col {
      background-color: #6b7385;
      color: #fff;
      font-size: 1rem;
      font-weight: normal;
      text-transform: uppercase;
    }

    div:last-of-type {
      border-right: 0;
    }
  }

  .tabulator-header .tabulator-col .tabulator-col-content,
  .tabulator-cell {
    padding-left: 0.5rem;
  }


  .tabulator-row,
  .tabulator-row-odd,
  .tabulator-row-even {
    background-color: color2.$gray-900;
    color: #fff;

    &:hover {
      background-color: color2.$gray-700;
      color: #fff;
    }

    .tabulator-cell {
      border-right: none;
    }
  }

  .tabulator-tree-level-0:not(.has-children) {
    .tabulator-cell:first-child {
      padding-left: 8px;
    }
  }

  .tabulator-row,
  .tabulator-cell {
    min-height: 35px;
  }

  .tabulator-row.opened {
    color: bootstrap.$primary;
    font-weight: bold;

    &:hover {
      background-color: color2.$gray-900;
    }

    .child-container {
      font-weight: normal;
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }

  .tabulator-row.tabulator-group {
    background-color: color2.$gray-800;
    border-right: none;
    color: #fff;
    font-weight: normal;

    span {
      color: #fff;
    }
  }

  .tabulator-row.tabulator-group.tabulator-group-visible .tabulator-arrow {
    border-bottom: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid bootstrap.$dark;
  }

  .tabulator-row.tabulator-group .tabulator-arrow {
    border-bottom: 6px solid transparent;
    border-left: 6px solid bootstrap.$dark;
    border-right: 0;
    border-top: 6px solid transparent;
  }

  .tabulator-footer {
    background-color: #6b7385;
    border-bottom: bootstrap.$dark 1px solid;
    border-left: bootstrap.$dark 1px solid;
    border-right: bootstrap.$dark 1px solid;
  }

  .collapse-icon,

  .expand-icon {
    font-size: 1.2rem;
  }


  .child-table {
    border: 1px solid bootstrap.$dark;

    .tabulator-header {
      background-color: #6b7385;
      border-bottom: none;

      .tabulator-col.tabulator-sortable {
        background-color: color2.$gray-900;
        border-right: none;

        &:hover {
          background-color: color2.$gray-900;
        }
      }
    }

    .tabulator-row,
    .tabulator-cell,
    .child-container {
      background-color: color2.$gray-700;

      &:hover {
        background-color: color2.$gray-700;
      }
    }

    .child-container {
      .child-table {
        border: 1px solid bootstrap.$dark;
      }

      .tabulator-header {
        border-bottom: none;
      }

      .tabulator-row,
      .tabulator-cell {
        background-color: color2.$gray-800;
      }
    }
  }
}
