.station-options {
  .station-options-jem {
    .prereqs {
      img.and-or {
        opacity: 0.5;
        width: 50px;
      }
    }
  }

  .trigger-config-container {
    margin-bottom: 25px;
  }
}
