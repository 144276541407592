@use '_variables';
@use 'bootstrap';

.component-inputs-search-box {
  .search-icon,
  .clear-icon {
    color: bootstrap.$dark;
    cursor: pointer;
  }

  .clear-icon {
    left: -25px;
    position: relative;
  }

  .search-term::placeholder {
    font-style: italic;
  }

  .disabled {
    .search-icon,
    .clear-icon {
      cursor: not-allowed;
      opacity: variables.$disabled-opacity;
    }
  }
}
