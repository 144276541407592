@use '_color';
@use 'bootstrap';
@use 'sass:map';

.id {
  text-align: center;
  width: 75px;
}

.center {
  text-align: center;
}

.form-invalid-label {
  background-color: #e65656;
  border-radius: 3px;
  color: white;
  font-size: map.get(bootstrap.$font-sizes, 6);
  font-style: italic;
  padding: 5px;
  white-space: nowrap;

  i {
    margin-right: 3px;
  }
}

.select-group {
  .input-group-prepend:first-child {
    padding-right: 16px;
    position: relative;
    right: -4px;
  }

  .input-group-prepend:last-child {
    left: -4px;
    padding-left: 16px;
    position: relative;
  }

  .input-group-append:first-child {
    position: relative;
    right: -4px;
  }

  .input-group-append:last-child {
    left: -4px;
    position: relative;
  }
}

.input-disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.form-check.form-switch input {
  cursor: pointer;
}
