@use '../_color';
@use 'bootstrap';
@use 'sass:map';

.eflex-navigate-tabs {
  background-color: white;
}

.eflex-tabs {
  border-bottom: 0.5px solid color.$gray-400;
  margin-bottom: 1rem;
  padding-left: 0;
  text-transform: capitalize;
  z-index: map.get(bootstrap.$zindex-levels, 'title-bar');

  .eflex-tab:not(:last-child) {
    margin-right: 35px;
  }

  .eflex-tab {
    cursor: pointer;
    display: inline;
    font-weight: 600;

    a {
      color: color.$gray-600;
      margin-bottom: -2px;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .nav-link {
    color: inherit;

    &:not(.active) {
      &:hover {
        color: color.$gray-900;
      }
    }

    &.active {
      border-bottom: 3px solid color.$primary;
      color: color.$primary;
    }

    &.disabled {
      opacity: 0.5;
    }
  }
}
