@use '_color';

.component-nav-header {
  align-items: center;
  background-color: color.$gray-800;
  display: flex;
  max-height: 50px;
  min-height: 50px;

  .ember-power-select-selected-item,
  .ember-power-select-placeholder {
    display: inline;
    margin-right: 5px;
  }
}
