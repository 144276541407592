@use '../_color';

.station-task-panel {
  .task-panel-tabs {
    flex: 1 1 auto;
    height: 0;
  }

  .tab-pane {
    height: 100%;
  }

  .task-panes-wrapper {
    height: 100%;
    width: auto;
  }

  #pane-task-wie {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .task-type-select {
    .ember-power-select-options {
      max-height: 550px;
    }
  }

  .enable-checkbox {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-top: 1.8rem;

    .eflex-checkbox {
      margin-right: 0;
    }
  }

  .context-col-enable {
    width: 75px;
  }

  .ignore-option {
    .eflex-checkbox {
      margin-right: 0;
    }
  }

  .context-specific-name {
    font-size: 0.875rem;
  }
}
