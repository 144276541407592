.eflex-scroll {
  overflow: auto;
  scrollbar-color: #999 transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background: transparent;
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9999;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 10px;

    &:hover {
      background: #999;
      background-clip: padding-box;
    }
  }
}

.overflow-overlay {
  overflow: overlay;
}

.overflow-x-hidden {
  overflow-x: hidden;
}
