.component-modals-json {
  .json-formatter-row {
    flex-direction: column !important;
  }

  .json-formatter-children {
    max-width: 100%;

    .json-formatter-row {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
