@use 'sass:color';
@use 'sass:map';
@use 'bootstrap';
@use '../_color' as color2;

.eflex-tree-criteria,
.tree-dock {
  min-height: 1px;

  .facet-menu .fa-camera {
    display: none;
  }

  .facet-menu .plant-task.app-tree-child .fa-camera {
    display: inline-block;
    margin-left: 5px;
    margin-top: 7px;
    position: relative;
    z-index: map.get(bootstrap.$zindex-levels, 'popover');
  }

  .app-tree-child.plant-task:last-of-type {
    padding-bottom: 5px;
  }

  .facet-container {
    background: color2.$gray-0;
    color: color2.$gray-700;

    .fa:hover {
      color: color2.$dark;
      cursor: pointer;
    }

    .tree-menu-item {
      border-radius: 3px;
      display: block;
      padding: 5px 5px 1px;
      position: relative;

      &:hover {
        background: #a2a2a250;
      }

      .tree-item-label {
        cursor: pointer;
        text-overflow: ellipsis;
      }
    }

    .facet {
      border: none;
      border-radius: 3px;
      margin: 0;

      .facet-menu {
        max-height: calc(100vh - 200px);
        overflow-x: hidden;
        overflow-y: auto;
        padding: 10px;

        .toggle-children {
          cursor: pointer;
          display: block;
          float: left;
          margin: 7px 5px 0 10px;
          position: relative;
          z-index: map.get(bootstrap.$zindex-levels, 'popover');
        }
      }

      ul {
        color: color2.$gray-700;
        font-weight: bold;
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      li {
        background-position: left center;
        background-repeat: no-repeat;
        font-size: 13px;
        margin: 2px 0;
        padding: 2px 5px 0 10px;
        white-space: nowrap;

        &.plant-area {
          margin: 0;
          padding-left: 0;
        }
      }

      ul ul {
        font-weight: normal;
      }

      ul ul ul ul {
        margin-left: 16px;
      }
    }
  }

  .search-term {
    height: 1.5rem;
  }
}

.tree-dock {
  margin-bottom: 10px;
  margin-right: -8px;
  margin-top: -15px;
  position: relative;
  width: 485px;
  z-index: map.get(bootstrap.$zindex-levels, 'dockable');

  .input-search-box {
    width: 95%;
  }

  &:not(.closed) {
    margin-left: 15px;
  }

  &.closed {
    width: 0;

    .facet-handle {
      left: -17px;
    }
  }

  .tree-util-bar {
    background: color.adjust(color2.$gray-0, $lightness: -10%);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 20%);
    height: 32px;
    margin-bottom: 10px;
    padding: 5px 0 7px 10px;
  }

  .facet-handle {
    background: color.adjust(color2.$gray-0, $lightness: -10%);
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    box-shadow: (-2px) 2px 2px rgba(0, 0, 0, 20%);
    color: color2.$gray-700;
    cursor: pointer;
    height: 32px;
    left: -25px;
    padding-top: 6px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 25px;
  }

  .app-add-area {
    margin: 0 0 10px 10px;
  }

  .tree-add-area-textbox {
    margin-bottom: 5px;
  }

  .drag-hovered {
    border: 2px blue solid;
  }

  .lock {
    visibility: collapse;
  }

  .locked {
    .lock {
      display: inline-block;
      visibility: visible;
    }
  }

  .tree-menu-item-controls {
    display: none;
    position: absolute;
    right: 5px;
    top: 7px;
    width: 50px;
  }

  .facet-container.with-border {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 30%);
  }

  .active:not(.tree-task) {
    background: rgba(color2.$primary, 0.5);
    border-radius: 3px;

    &:hover {
      background: rgba(color2.$primary, 0.5);
    }

    + .tree-menu-item {
      background: rgba(color2.$primary, 0.5);
      border-radius: 3px;

      &:hover {
        background: rgba(color2.$primary, 0.5);
      }
    }
  }

  .tree-menu-item:hover {
    .tree-menu-item-controls {
      display: inline-block;

      &:hover {
        .popup-content {
          display: block;
        }
      }
    }
  }

  .station-icon {
    background-repeat: no-repeat;
    background-size: 20px 20px;
    display: inline-block;
    height: 20px;
    margin-left: 5px;
    position: relative;
    vertical-align: middle;
    width: 30px;
    z-index: map.get(bootstrap.$zindex-levels, 'popover');

    + .holder {
      display: inline-block;
      vertical-align: -webkit-baseline-middle;
    }
  }
}

.eflex-tree-criteria {
  .facet-search {
    padding: 0;

    input {
      width: 70%;
    }

    form i {
      margin: 2px;
    }
  }

  .facet-container .facet .plant-station {
    margin-left: 20px;
  }

  .search-filter-container {
    float: right;
    font-size: 13px;
    text-align: right;
  }
}
