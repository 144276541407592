.eflex-settings-backup {
  .backup-now {
    background-color: #c0c0c0;
    border: none;
    border-radius: 5px;
    color: #5c5853;
    font-size: 17px;
    height: 40px;
    line-height: 35px;
    margin: 10px;
    transition: all 0.2s ease-in-out;
    width: 120px;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: #adadad;
    }

    &:active {
      background-color: #9c9c9c;
    }
  }

  .select-days {
    .col-form-label {
      text-align: center;
    }
  }

  .collapse-fieldset {
    margin: 10px 0;

    fieldset {
      background-color: #ededed;
      border: none;
      border-radius: 5px;
      box-shadow: 2px 2px 2px #c4c4c4;
      width: auto;
    }
  }

  .settings-backup-day-lbl {
    margin: 2px;
    margin-right: 5px;
  }
}
