@use 'sass:color';
@use '_color' as styles-color;
@use 'bootstrap';

.component-add-button {
  .btn-eflex {
    background: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    font-weight: bootstrap.$font-weight-light;
    margin: 0;
    padding: 0;
  }

  .btn-eflex:hover {
    border-bottom: none;
    box-shadow: none;
  }

  .btn-eflex-placeholder,
  .btn-eflex-plus {
    color: styles-color.$primary;
    font-weight: 200;
    line-height: 1.25rem;
    user-select: none;
  }

  .btn-eflex-placeholder:hover,
  .btn-eflex-plus:hover {
    color: color.adjust(styles-color.$primary, $lightness: -5%);
  }

  .btn-eflex-placeholder:hover {
    border-bottom: 1px solid color.adjust(styles-color.$primary, $lightness: -5%);
  }

  .btn-eflex-plus {
    font-size: 1.75rem;
    position: relative;
    top: 3px;
  }

  .btn-eflex-placeholder {
    border-bottom: 1px solid styles-color.$primary;
    font-size: 1.25rem;
    margin-left: 5px;
  }

  &.btn-eflex-sm {
    .btn-eflex-plus {
      font-size: 1.25rem;
      position: relative;
      top: 2px;
    }

    .btn-eflex-placeholder {
      font-size: 0.875rem;
    }
  }
}
