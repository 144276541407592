@use '_color' as color2;

.component-date-picker-with-tokens {
  .ember-power-select-trigger,
  .form-control {
    color: #0f0f0f;
  }

  .btn {
    color: color2.$gray-600;
    font-weight: unset;
    padding-left: 0;
    padding-top: 1.5625rem;
  }

  .form-group {
    margin-bottom: 0.625rem;
  }

  .icon-schedule {
    color: color2.$gray-600;
    font-size: 1.75rem;
  }

  .flatpickr-input {
    background-clip: padding-box;
    border: 0.0625rem solid #b5b5b5;
    border-radius: 0.25rem;
    display: block;
  }
}
