// Bootstrap styles
$ember-power-select-line-height: 2 !default;
$ember-power-select-border-color: #ccc !default;
$ember-power-select-trigger-icon-color: #999 !default;
$ember-power-select-focus-outline: 0 !default;
$ember-power-select-opened-border-radius: 4px !default;
$ember-power-select-search-input-border-radius: 3px !default;
$ember-power-select-dropdown-margin: 3px !default;
$ember-power-select-dropdown-box-shadow: rgba(0,0,0, 0.172549) 0px 6px 12px 0px !default;
$ember-power-select-highlighted-color: inherit !default;
$ember-power-select-highlighted-background: #f5f5f5 !default;
$ember-power-select-selected-background: #f5f5f5 !default;
$ember-power-select-focus-border-color: #66afe9 !default;
$ember-power-select-focus-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6) !default;
$ember-power-select-dropdown-border: 1px solid $ember-power-select-border-color !default;
$ember-power-select-dropdown-contiguous-border: $ember-power-select-dropdown-border !default;
