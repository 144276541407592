@use 'sass:color';
@use 'sass:map';
@use '_color' as color2;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

@use 'bootstrap' with (
  $body-bg: color2.$gray-700,
  $enable-cssgrid: true,
  $grid-gutter-width: 1rem,

  /* Fonts*/
  $rfs-base-value: 1rem,
  $rfs-breakpoint: 1400,
  $rfs-class: enable,
  $font-family-sans-serif: 'Source Sans Pro',

  /* Color Theme*/
  $theme-colors: (
    primary: color2.$primary,
    secondary: color2.$secondary,
    success: color2.$success,
    danger: color2.$danger,
    warning: color2.$warning,
    info: color2.$info,
    light: color2.$light,
    dark: color2.$dark,
    gray-0: color2.$gray-0,
    gray-100: color2.$gray-100,
    gray-200: color2.$gray-200,
    gray-300: color2.$gray-300,
    gray-400: color2.$gray-400,
    gray-500: color2.$gray-500,
    gray-600: color2.$gray-600,
    gray-700: color2.$gray-700,
    gray-800: color2.$gray-800,
    gray-900: color2.$gray-900,
    gray-1000: color2.$gray-1000,
  ),

  /* Font Sizes */
  $font-sizes: (
    1: 1.5rem,
    2: 1.25rem,
    3: 1rem,
    4: 0.875rem,
    5: 0.75rem,
    6: 0.625rem,
  ),

  $font-weight-light: $font-weight-light,
  $font-weight-normal: $font-weight-normal,
  $font-weight-semibold: $font-weight-semibold,
  $font-weight-bold: $font-weight-bold,

  $font-size-base: 1rem,
  $font-size-sm: 0.875rem,
  $font-size-lg: 2rem,

  $h1-font-size: 4rem,
  $h2-font-size: 2.5rem,
  $h3-font-size: 2.25rem,
  $h4-font-size: 2rem,
  $h5-font-size: 1.5rem,
  $h6-font-size: 1.25rem,
  $headings-font-weight: $font-weight-bold,

  $small-font-size: 0.875rem,

  $border-radius: 0.1875rem,
  $border-radius-sm: 0.1875rem,
  $border-radius-lg: 0.1875rem,

  $min-contrast-ratio: 2,
  /* determines text color on buttons*/
  $color-contrast-dark: color2.$default-text,

  /* Tables*/
  $table-bg: color2.$gray-0,
  $table-cell-padding-y-sm: 0.3rem,
  $table-cell-padding-x-sm: 0.5rem,
  $table-cell-vertical-align: center,

  /* Legends*/
  $legend-font-size: 1rem,
  $legend-font-weight: 700,

  /* Buttons*/
  $btn-disabled-opacity: 0.25,
  $btn-active-border-shade-amount: 0%,
  $btn-active-border-tint-amount: 0%,

  $input-btn-font-size-sm: 0.75rem,
  $input-btn-padding-y-sm: 0.0625rem,

  $input-btn-padding-y-lg: 0.5625rem,
  $input-btn-padding-x-lg: 1.75rem,

  /* Labels*/
  $form-label-margin-bottom: 0.25rem,
  $form-label-font-size: 0.875rem,
  $form-label-font-weight: 600,
  $form-label-color: color2.$gray-800,

  /* Inputs*/
  $input-bg: color2.$gray-0,
  $input-color: color2.$default-text,
  $input-border-color: color2.$gray-400,
  $input-placeholder-color: color2.$gray-400,
  $input-disabled-bg: #fff,

  $input-focus-border-color: color2.$primary,
  $input-focus-box-shadow: 0 0 0 4px color.adjust(color2.$primary, $alpha: -0.4),

  /* Dropdowns */
  $dropdown-bg: color2.$gray-0,

  /* Forms*/
  $enable-validation-icons: false,
  $form-feedback-valid-color: color2.$gray-400,

  /* Checkboxes*/
  $form-check-input-checked-bg-color: color2.$primary,

  /* List Groups*/
  $list-group-bg: color2.$gray-0,

  /* Tabs*/
  $nav-link-padding-y: 0,
  $nav-link-padding-x: 0,

  /* Cards*/
  $card-spacer-y: 0,
  $card-spacer-x: 0.25rem,
  $card-bg: color2.$gray-0,
  $card-cap-bg: color2.$primary,
  $card-cap-color: color2.$gray-0,
  $card-cap-padding-x: 0,
  $card-cap-padding-y: 0,
  $card-border-color: color2.$gray-200,
  $card-border-radius: 4px,
  $card-inner-border-radius: 2px,
  $card-border-width: 1px,

  /* Modals*/
  $modal-footer-margin-between: 0,
  $modal-content-bg: color2.$gray-100,
  $modal-content-border-color: color2.$gray-800,
  $modal-content-border-width: 7px,
  $modal-header-border-color: color2.$gray-800,
  $modal-header-padding-x: 20px,
  $modal-header-padding-y: 0,
  $modal-footer-bg: color2.$gray-800,
  $modal-footer-border-width: 0,
  $modal-sm: 450px,
  $modal-md: 600px,
  $modal-lg: 900px,
  $modal-xl: 1200px,

  /* Progress*/
  $progress-height: 0.875rem,
  $progress-border-radius: 50rem,

  /* Badges*/
  $badge-font-weight: 600,
  $badge-font-size: 0.875rem,
  $badge-padding-x: 0.5rem,
  $badge-padding-y: 0.375rem,
  $badge-border-radius: 0.625rem,

  /* Pagination*/
  $pagination-bg: color2.$gray-0,

  /* Accordions*/
  $accordion-bg: color2.$gray-0,

  /* Popovers*/
  $popover-bg: color2.$gray-0,

  $primary: color2.$primary,
  $secondary: color2.$secondary,
  $success: color2.$success,
  $danger: color2.$danger,
  $warning: color2.$warning,
  $info: color2.$info,
  $light: color2.$light,
  $dark: color2.$dark,

  /* Thumbnails */
  $thumbnail-bg: color2.$gray-0,

  $zindex-levels: (
    n1: -1,
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    task-grid: 1,
    above-task-cards: 2,
    wie-delete-icon: 2,
    table-header: 3,
    title-bar: 4,
    dockable: 5,
    above-dock: 6,
    wie-context-menu: 10,
    top-menu: 1025,
    side-panel: 1030,
    modal-backdrop: 1040,
    modal: 1050,
    ajax-loader: 1051,
    criteria-visor: 1052,
    popover: 1054,
    select-options: 1058,
    spinner: 1090,
  ),

  $utilities: (
    opacity: (
      property: opacity,
      class: o,
      values: (
        0: 0,
        25: 0.25,
        50: 0.5,
        75: 0.75,
        100: 1,
      ),
    ),
    'font-weight': (
      property: font-weight,
      class: fw,
      values: (
        light: $font-weight-light,
        normal: $font-weight-normal,
        semibold: $font-weight-semibold,
        bold: $font-weight-bold,
      )
    )
  )
);
