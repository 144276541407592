@use 'bootstrap';
@use 'sass:map';

.ajax-text-indicator {
  background-color: rgba(255, 255, 255, 100%);
  bottom: 0;
  display: block;
  height: 6%;
  position: absolute;
  width: 100%;
  z-index: map.get(bootstrap.$zindex-levels, 'ajax-loader');
}

.component-spinner {
  left: 50%;
  position: relative;
  top: 50%;
  width: 0;
  z-index: map.get(bootstrap.$zindex-levels, 'spinner');
}

.spinner-overlay {
  .spinner-border {
    height: 3rem;
    width: 3rem;
  }
}
