@use 'sass:color';
@use '_color' as color2;
@use '_variables';
@use 'bootstrap';

.jem-info-bar,
.jem-station-dropdown {
  .form-control {
    background-color: color2.$gray-900;
    border-color: color2.$gray-200;
    color: color2.$gray-0 !important;
  }

  .form-control:hover {
    border-color: color2.$gray-100;
  }

  .form-control:focus{
    border-color: color2.$gray-0;
  }

  input:read-only {
    background-color: #{color2.$gray-800} !important;
    border: 0;
    color: color2.$gray-0 !important;
  }

  label {
    color: color2.$gray-0 !important;
  }
}

.timer-text {
  line-height: 1;

  .timer-deci-seconds {
    height: 2rem;
  }
}

.jem-image-upload {
  display: block;
  margin: 0 auto;
}

.form-group.jem-content-container {
  height: calc(100% - 70px);
}

.standard-modal.jem-notes-modal {
  .modal-content {
    .gray-0 {
      display: inherit;

      > div {
        display: block;
      }
    }
  }
}

.jem-notes-modal {
  .form-group {
    width: 100%;
  }
}

.jem-text-success {
  color: #0f6104 !important;
}

.jem-text-danger {
  color: color2.$danger !important;
}

.jem-bg-success {
  background-color: color2.$success;
}

.jem-bg-danger {
  background-color: color2.$danger;
}
