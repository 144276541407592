.component-file-input {
  /* stylelint-disable-next-line selector-class-pattern */
  .x-file--input {
    height: 0;
    max-width: 0;
    opacity: 0;
    overflow: hidden;
    width: 1px;
    z-index: -1;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .x-file--input + label {
    cursor: pointer;
    display: inline-block;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .x-file--input:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .x-file--input + label * {
    pointer-events: none;
  }
}
