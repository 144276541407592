@use 'sass:color';
@use '_color' as styles-color;

$title-height: 30px;

.component-title-bar {
  background: color.adjust(styles-color.$gray-700, $lightness: 20%);
  height: $title-height;
  margin: 0;
  width: 100%;

  .screen-name,
  .section-name {
    align-items: center;
    color: #fff;
    display: flex;
    float: left;
    font-size: 12px;
    letter-spacing: 1px;
    max-width: 55%;
    overflow: hidden;
    padding: 0 15px;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .section-name {
    background: styles-color.$gray-700;
    height: $title-height;
    max-width: 55%;
    position: relative;

    &::after {
      border-style: solid;
      border-width: 15px 0 15px 5px;
      content: '';
      display: block;
      position: absolute;
      right: -5px;
      top: 0;
      width: 0;
    }
  }

  .screen-name {
    height: $title-height;
    max-width: 45%;
  }

  .action-title-strip {
    color: rgba(#fff, 0.8);
    font-size: 11px;
    height: $title-height;

    .btn {
      font-weight: bold;
      margin-right: 10px;
    }
  }
}
