@use 'sass:color';
@use '_color' as styles-color;

$card-width: 113px;

.component-work-instruction-editor-library-task {
  .library-search {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  button {
    &:focus {
      box-shadow: none;
    }
  }

  button.clear {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    position: absolute;
    right: 0;
    width: 30px;
  }

  button.search {
    background-color: styles-color.$gray-100;
    border: 1px solid styles-color.$gray-600;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    justify-content: center;
    padding-top: 2px;
    width: 30px;

    &:active {
      background-color: color.adjust(styles-color.$gray-100, $lightness: -10%);
    }
  }

  .library-carousel-container {
    background: white;
    border: 1px solid styles-color.$gray-600;
    color: styles-color.$gray-600;
    display: flex;
    height: 170px;
    justify-content: center;
    overflow: hidden;
    padding: 5px;

    .carousel-arrow {
      align-items: center;
      display: flex;
      font-size: 20px;
      max-width: 10px;
      width: 10px;

      button {
        border: none;
        color: styles-color.$gray-600;
        cursor: pointer;
        height: 100%;
        outline: none;
        padding: 0;

        &:active {
          color: color.adjust(styles-color.$gray-600, $lightness: -20%);
        }
      }
    }
  }

  .folder-title {
    color: styles-color.$light-text;
    font-size: 12px;
    margin: 5px;
    max-width: $card-width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon-wie-folder {
    height: 75px;
    min-height: 75px;
  }

  .return-card {
    .library-card {
      border: none;
    }

    .library-return-card {
      margin-top: 17px;
    }

    .icon-wie-library-return {
      width: 70px;
    }
  }

  .library-card {
    border: 1px solid styles-color.$gray-600;
    color: styles-color.$default-text;
    cursor: pointer;
    height: 148px;
    margin: 5px;
    max-height: 148px;
    padding: 5px;
    width: $card-width !important;

    &.active {
      background-color: color.adjust(white, $lightness: -10%);
    }

    &:hover,
    &.hovered {
      background-color: styles-color.$default-hover;
    }

    .thumb {
      align-items: center;
      display: flex;
      height: 78px;
      justify-content: center;
    }

    img {
      margin-bottom: 4px;
      max-height: 75px;
      max-width: 100px;
    }

    .name {
      font-size: 12px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .info {
      font-size: 10px;
      line-height: 1.3;
    }

    .notes {
      display: none;
    }
  }
}
