@use 'sass:color';
@use 'sass:map';
@use '../_color' as color2;
@use 'bootstrap';

$tabs-height: 40px;
$tab-padding: 1rem;

.get-started-button {
  background-color: color2.$primary;
  border: 1px solid #bb8200;
  border-radius: 4px;
  color: color2.$secondary;
  font-weight: lighter;
  line-height: 1.4;
  padding: 4px 0 0;
  text-transform: uppercase;
  width: 180px;

  &:hover {
    background-color: color2.$primary;
    color: color2.$secondary;
    opacity: 0.85;
  }

  .sm-text {
    display: block;
    font-size: 14px;
  }

  .lg-text {
    display: block;
    font-size: 22px;
  }
}

.portal {
  .wie-enlarged-image {
    align-items: center;
    background-color: rgba(0, 0, 0, 80%);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;

    .close-image {
      color: #ccc;
      cursor: pointer;
      font-size: 20px;
      position: fixed;
      right: 20px;
      top: 20px;
    }

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .wie-get-started-modal {
    .gray-0 {
      flex-direction: row;
      overflow: hidden;
      padding: 0;
    }

    .size-cards {
      display: grid;
      grid-auto-rows: max-content;
      grid-gap: 30px;
      grid-template-columns: repeat(3, 140px);
      justify-content: normal;
      overflow-y: auto;
      padding: 30px;

      .wie-size-card {
        height: 152px;
        width: 140px;

        .size-card-background {
          background-color: #d6dee6;
          border: 1px solid #949dad;
          border-radius: 6px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          height: 108px;
          justify-content: flex-end;
          margin-bottom: 2px;
          width: 100%;

          &.not-ready {
            cursor: not-allowed;

            &:hover {
              background-color: #d6dee6;
            }
          }

          &:hover {
            background-color: #e9f1f9;
          }

          .preview-box {
            background-color: #e9edf0;
            border: 1px solid #949dad;
            border-radius: 6px;
            margin: 4px auto;
            max-height: calc(100% - 32px);
            max-width: calc(100% - 24px);
          }

          .dimensions {
            color: #9d9e9f;
            font-size: 11px;
            font-style: italic;
            margin-bottom: 3px;
            padding-right: 8px;
            text-align: center;
            width: 100%;
          }
        }

        .size-card-title {
          color: #545a69;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .size-card-subtitle {
          color: #545a69;
          font-size: 13px;
          line-height: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .size-card-custom {
          color: #6d6d6d;
          font-size: 12px;
          font-style: italic;
          margin-top: 2px;

          .filled {
            .size-card-input {
              background: white;
              font-style: normal;
            }
          }

          .size-card-input {
            background: transparent;
            border-bottom: 2px solid #d8d8d8;
            border-left: none;
            border-right: none;
            border-top: none;
            color: #6d6d6d;
            font-size: 12px !important;
            font-style: italic;
            line-height: 12px;
            padding-right: 2px;
            text-align: right;
            width: 50px;

            &::placeholder {
              color: #939393 !important;
              padding-right: 5px;
            }

            &.width {
              margin-right: 6px;
            }

            &.height {
              margin-left: 6px;
            }
          }

          .min-text {
            color: #9d9e9f;
            font-size: 11px;
            margin-top: 4px;
            padding-left: 7px;
          }
        }
      }
    }

    .right-panel-wrapper {
      height: 100%;
      padding: 30px 0;
      width: 165px;

      .right-panel {
        border-left: 1px solid #535a6a;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding: 0 18px;
        width: 100%;

        .right-panel-header {
          color: #777;
          font-size: 12px;
          margin-bottom: 18px;
          margin-top: -4px;
          text-transform: uppercase;
        }

        .recent-files {
          .recent-file {
            cursor: pointer;
            margin-bottom: 18px;

            .name {
              color: #58595a;
              font-size: 12px;
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .detail {
              color: #9d9e9f;
              font-size: 11px;
              line-height: 13px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .view-library {
          color: color2.$warning;
          cursor: pointer;
          font-size: 12px;
          text-decoration: underline;
          text-transform: uppercase;
        }
      }
    }
  }

  .wie-save-modal {
    .gray-0 {
      .work-instruction-notes input {
        height: 112px;
        resize: none;
      }
    }
  }

  .wie-approve-reject-modal {
    .gray-0 {
      color: #5c5c5c;
      padding: 15px 30px 42px;

      .carousel-control-prev,
      .carousel-control-next {
        width: 3%;
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        background: no-repeat 50% / 100% 100%;
        display: inline-block;
        height: 35px;
        width: 35px;
      }

      .carousel-control-next-icon {
        background-image: url('/images/carouselarrow-next.svg');
        margin-bottom: 145px;
        margin-left: 10px;
      }

      .carousel-control-prev-icon {
        background-image: url('/images/carouselarrow-previous.svg');
        margin-bottom: 145px;
        margin-right: 10px;
      }

      .wie-approve-reject-slide {
        align-items: stretch;
        display: inline-flex;
        flex-direction: row;
        height: 360px;
        max-width: 100%;
        width: 100%;

        .slide-panel {
          width: 390px;

          .slide-panel-header {
            font-size: 14pt;
            line-height: 11pt;
            margin-bottom: 10px;
            text-align: center;
            width: 100%;

            .enlarge-image {
              color: #535a6a;
              cursor: pointer;
              float: right;
              font-size: 15px;
              height: 15px;
              margin-left: -15px;
              width: 15px;

              &.disabled {
                cursor: not-allowed;
                opacity: 0.5;
              }
            }
          }

          .instruction-image {
            align-items: center;
            background-color: color2.$gray-100;
            border: 1px solid #ccc;
            display: flex;
            height: 276px;
            justify-content: center;
            margin-bottom: 10px;
            width: 100%;

            img {
              max-height: 100%;
              max-width: 100%;
            }

            .no-image {
              background-color: #75747f;
              height: 150px;
              mask: url('/images/do-not-icon.svg') no-repeat;
              width: 150px;
            }
          }

          .instruction-name {
            font-size: 9pt;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &.right-panel {
            color: color2.$warning;

            .slide-panel-header .enlarge-image {
              color: color2.$warning;
            }

            .instruction-image {
              border-color: color2.$warning;
            }

            .instruction-name {
              float: left;
              font-size: 9pt;
              max-width: 330px;
            }
          }
        }
      }

      .instruction-text {
        font-size: 14px;
        padding-left: 45px;
      }

      .note-container {
        padding-right: 45px;
      }

      .note-label {
        float: left;
        text-transform: uppercase;
      }

      .approve-reject-notes {
        float: left;
        font-size: 10pt;
        height: 70px;
        resize: none;
      }

      .approve-reject-control {
        height: 46px;
        margin-right: 15px;
        position: absolute;
        right: 0;
        top: 310px;
        width: 54px;
        z-index: 800;

        .approve-btn,
        .reject-btn {
          cursor: pointer;
          height: 28px;
          position: absolute;
          width: 28px;
        }

        .approve-btn {
          top: 0;
        }

        .reject-btn {
          bottom: 0;
          right: 0;
        }

        &.pending {
          background: url('/images/icons/imageEditor/approve-reject-pending-icon.svg') center center no-repeat;
        }

        &.rejected {
          background: url('/images/icons/imageEditor/rejected2-icon.svg') center center no-repeat;
        }

        &.approved {
          background: url('/images/icons/imageEditor/approved2-icon.svg') center center no-repeat;

          .detail-text {
            font-size: 13pt;
          }
        }
      }
    }

    .approvers {
      .title {
        font-weight: bold;
        padding-top: 12px;
        text-transform: uppercase;
      }
    }
  }

  .wie-deployment-modal {
    .gray-0 {
      font-size: 12pt;
      padding: 36px;

      .instruction-list {
        margin-top: 10px;
        padding-left: 18px;

        li {
          text-decoration: underline;
        }
      }
    }
  }
}

.no-chrome-page {
  .work-instructions-page {
    .work-instructions-header {
      display: flex;
      height: 60px;
    }

    .work-instructions-title {
      color: white;
      display: flex;
      font-size: 32px;
      font-weight: bootstrap.$font-weight-light;
      overflow: hidden;
      padding-left: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      .work-instruction-title-name {
        font-style: italic;
      }
    }

    .work-instructions-workspace {
      .square-button {
        font-size: map.get(bootstrap.$font-sizes, 4);
      }

      .x-file-input {
        input {
          display: none;
        }
      }

      .work-instructions-header {
        display: flex;
        height: 60px;
      }

      &.library-full-screen {
        .work-instruction-wrapper {
          width: auto;

          .right-drawer.toggle,
          .work-instruction-main {
            display: none !important;
          }
        }

        .property-drawer-wrapper {
          width: 100%;

          .property-drawer {
            max-width: 100%;
            width: 100%;
          }

          .tab-container {
            width: 100%;

            .editor-tabs {
              float: right;
              margin-right: -10px;
              width: 270px;
            }

            .options-contents {
              clear: both;
              padding-top: 10px;
            }

            .search-contents {
              float: right;
              margin-bottom: 20px;
              width: 231px;

              button {
                padding-top: 5px;
              }
            }
          }
        }
      }
    }

    .property-drawer {
      height: 100%;

      /* stylelint-disable-next-line selector-class-pattern */
      .ps__scrollbar-y-rail {
        width: 8px;
      }
    }

    .work-instruction-wrapper {
      display: flex;
      width: 100%;

      .work-instruction-main {
        height: 100%;
        position: relative;
        width: 100%;

        .get-started {
          bottom: 0;
          height: 70px;
          left: 0;
          margin: auto;
          max-height: 100%;
          max-width: 100%;
          overflow: auto;
          position: absolute;
          right: 0;
          text-align: center;
          top: 0;
          width: 300px;
        }
      }

      .toggle {
        background-color: #f0f0f0;
        color: #a1a3a6;
        display: flex;
        flex-direction: column;
        float: left;
        height: 100%;
        justify-content: center;
        z-index: map.get(bootstrap.$zindex-levels, 'dockable');
      }
    }

    .work-instruction-main {
      height: 100%;
      width: 100%;

      .draggable-object-target {
        height: 100%;
        width: 100%;
      }

      &.work-instruction-grid.grid-right {
        .upper-canvas {
          border-right: 1px #5a5a5a solid;
        }
      }

      &.work-instruction-grid.grid-bottom {
        .upper-canvas {
          border-bottom: 1px #5a5a5a solid;
        }
      }
    }

    .canvas-container {
      align-items: center;
      display: flex;
      height: 100% !important;
      justify-content: center;
      width: 100% !important;

      canvas {
        left: unset !important;
        top: unset !important;
      }
    }

    .tab-container {
      width: 260px;

      .editor-tabs {
        border-bottom: 1px solid #ababab;
        margin-bottom: 6px;
        padding-left: 15px;
        position: relative;
        right: 10px;
        width: calc(100% + 10px);
        z-index: map.get(bootstrap.$zindex-levels, 'above-dock');
      }

      .eflex-tab {
        font-weight: normal;
        margin-right: 25px;

        a {
          padding-bottom: 0;
        }

        &:last-of-type {
          margin-right: 5px;
        }

        .active {
          color: color2.$primary;
        }
      }

      .tab-content {
        .tab-pane {
          height: 100%;
        }
      }
    }
  }

  #library {
    .library-contents .library-contents-inner {
      padding-left: 0;
      padding-right: 6px;
    }
  }

  hr {
    border-top: 1px solid color.adjust(white, $lightness: -40%);
    margin-bottom: 12px;
    margin-top: 18px;

    &.lighter {
      border-top: 1px solid color.adjust(white, $lightness: -15%);
    }
  }

  .main-footer {
    @extend .bg-gray-900, .text-gray-300;

    .square-button {
      @extend .btn-lg;
    }

    .footer-controls {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-end;

      button {
        padding: 0 20px;
      }
    }
  }

  .ember-power-select:focus,
  .ember-power-select-trigger:focus,
  .btn:focus {
    outline: none;
  }
}

.stroke-style-options,
.stroke-style-select {
  .ember-power-select-option,
  .ember-power-select-selected-item {
    align-items: center;
    display: flex;
    height: 26px;
  }

  label.stroke-option {
    height: 3px;
    margin-right: 0.75rem;
    width: 100%;
  }

  .solid {
    border-top: 3px solid black;
  }

  .dashed {
    border-top: 3px dashed black;
  }

  .dotted {
    border-top: 3px dotted black;
  }
}

.context-menu {
  background-color: #edebec;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 20%), 0 6px 20px 0 rgba(0, 0, 0, 19%);
  cursor: context-menu;
  position: fixed;
  width: 150px;
  z-index: map.get(bootstrap.$zindex-levels, 'wie-context-menu');

  ul {
    margin: 0;
    padding: 10px 0;

    li {
      cursor: pointer;
      font-size: 16px;
      list-style-type: none;
      padding: 2px 12px;

      &:hover {
        background-color: color2.$primary;
      }

      .pull-right {
        color: color.adjust(white, $lightness: -50%);
        font-size: 14px;
        margin-top: 2px;
      }
    }
  }
}

.wie-info-modal:focus {
  outline: none;
}
