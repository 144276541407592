.component-web-cam {
  img {
    position: absolute;
    z-index: 1000;
  }

  video {
    position: absolute;
    z-index: 1;
  }
}
