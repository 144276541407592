@use '_color';

.component-bi-average-quality-label {
  .quality {
    color: color.$success;
    font-weight: bolder;
    padding-right: 55px;
  }

  .rejects {
    color: color.$danger;
    font-weight: bolder;
    padding-right: 25px;
  }

  .label {
    color: color.$gray-600;
    font-size: 1rem;
    text-transform: uppercase;
  }
}
