.landing-page {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: -50px;

  img {
    width: 50%;
  }
}
