@use '../_color';

.eflex-area-tasks {
  .group-row {
    background: #fff;
    color: #5c5853;
    font-size: 8pt;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: left;
    text-transform: uppercase;
  }

  .station-row {
    background-color: color.$gray-100;
  }

  .indented {
    font-size: 9pt;
    padding-left: 20px;
  }
}
